import React, { useEffect, useState } from 'react';
import { useApiRequest } from 'hooks/useApiRequest';
import {
  Button,
  SectionHeader,
  Wrapper,
  SpecialistStats,
  UserAvatar,
  OrderTimeRange,
} from 'ui';
import { BASE_URL, finishOrder } from 'api/api';
import { useNavigate, useParams } from 'react-router';
import { OrderFullInfo } from 'utils/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { formatCurrency } from 'utils/validations';
import { useTranslation } from 'react-i18next';
import { getTypedStorageItem } from 'utils/storage';

const OrderDetailedPage = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { uid } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { data: order, loading } = useApiRequest<OrderFullInfo>({
    endpoint: `${BASE_URL}orders/${id}`,
  });

  const formatedPrice = formatCurrency(
    order?.currency ? order?.currency : 'USD',
    order?.price ? order?.price : 0,
  );

  const locale = getTypedStorageItem('selectedLanguage') || 'en';

  useEffect(() => {
    if (!loading && uid !== order?.stringSpecId) {
      navigate('/error');
    }
  }, [loading, order, uid, navigate]);

  const handleFinishOrder = async () => {
    if (order?.stringId) {
      setIsLoading(true);
      const result = await finishOrder(order?.stringId);
      if (result) {
        setIsLoading(false);
        toast.success('Order finished successfully');
        navigate('/specialist/orders');
      } else {
        setIsLoading(false);
        toast.error(t('something_wrong'));
      }
    }
  };

  const isFinishedOrder =
    Date.now() > (order?.timeOfOrder || 0) + (order?.duration || 0);

  return (
    <Wrapper flexColumnSpaceBetween>
      {loading ? (
        <SectionHeader
          title={t('message.loading')}
          titleClassName='animate-pulse'
        />
      ) : (
        <>
          <div>
            <SectionHeader
              title={t('orders.name')}
              subtitle={order?.address || ''}
            />
            <div className='mt-7 flex items-center justify-evenly'>
              <UserAvatar size={106} showLikeButton={false} />
              <SpecialistStats
                rank={order?.clientRating || 0}
                name={order?.clientName || 'Client name'}
              />
            </div>
            <ul className='mt-7 flex flex-col gap-5'>
              <li className='flex justify-between border-b border-gray-3 border-opacity-50 pb-3'>
                <p className='text-secondary'>{t('order.time')}</p>
                <OrderTimeRange
                  timeOfOrder={order?.timeOfOrder || 0}
                  dur={order?.duration || 0}
                  wrapperClassName='text-lg leading-[22px] !text-primary'
                />
              </li>
              <li className='flex justify-between border-b border-gray-3 border-opacity-50 pb-3'>
                <p className='text-secondary'>{t('order.days')}</p>
                <p className='text-lg leading-[22px] text-primary'>
                  {order?.timeOfOrder &&
                    new Date(order.timeOfOrder).toLocaleDateString(locale, {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric',
                    })}
                </p>
              </li>
              <li className='flex justify-between border-b border-gray-3 border-opacity-50 pb-3'>
                <p className='text-secondary'>{t('order.service')}</p>
                <p className='text-lg leading-[22px] text-primary'>
                  {order?.serviceName}
                </p>
              </li>
              <li className='mt-3 flex justify-between pb-2'>
                <p className='text-secondary'>{t('order.total_price')}</p>
                <p className='text-lg leading-[22px] text-primary'>
                  {formatedPrice}
                </p>
              </li>
            </ul>
          </div>

          <div className='mt-[150px] flex flex-col gap-3'>
            <Button
              variant='yellow'
              className='w-full !whitespace-normal py-4'
              onClick={handleFinishOrder}
              loading={isLoading}
              disabled={isFinishedOrder}
            >
              {t('order.finish_order')}
            </Button>
            <div className='flex gap-4'>
              <Link
                to={`/specialist/orders/${id}/cancel`}
                state={order?.stringId}
                className='w-full'
              >
                <Button
                  className='w-full !whitespace-normal py-3'
                  disabled={isFinishedOrder}
                >
                  {t('cancel_order.name')}
                </Button>
              </Link>
              <Link to={`/specialist/orders`} className='w-full'>
                <Button
                  variant='primary'
                  className='w-full !whitespace-normal py-3'
                >
                  {t('my_orders.name')}
                </Button>
              </Link>
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default OrderDetailedPage;
