import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useAppSelector';
import { DayTimeSelector, TimeSlot } from 'ui';

type CustomTimeSlotsListProps = {
  timeSlots: {
    id: number;
    startTime: string;
    status: string;
    gserviceSpecialistId: string;
  }[];
  handleSelectTime: (time: Date, id: number) => void;
};

const timeRanges = (t: any) => [
  { start: 6, end: 10, label: t('morning'), index: 0 },
  { start: 10.5, end: 16, label: t('afternoon'), index: 1 },
  { start: 16.5, end: 21, label: t('evening'), index: 2 },
  { start: 22, end: 5.5, label: t('night'), index: 3 },
];

const TimeSlotsList = ({
  handleSelectTime,
  timeSlots,
}: CustomTimeSlotsListProps) => {
  const { t } = useTranslation();
  const { timeSlotId } = useAppSelector((state) => state.createOrderPage);
  const { timeSlotId: timeSlotIdOrder } = useAppSelector(
    (state) => state.orderTime,
  );
  const [selectedRange, setSelectedRange] = useState(0);

  // Filter timeRanges to only show those that have corresponding time slots
  const filteredTimeRanges = timeRanges(t).filter((range) =>
    timeSlots.some((slot) => {
      const date = new Date(slot.startTime);
      const hours = date.getHours() + date.getMinutes() / 60;
      if (range.start < range.end) {
        return hours >= range.start && hours < range.end;
      } else {
        // Handles the time ranges that span across midnight
        return hours >= range.start || hours < range.end;
      }
    }),
  );

  const filteredTimeSlots = timeSlots.filter((slot) => {
    const date = new Date(slot.startTime);
    const hours = date.getHours() + date.getMinutes() / 60;

    return Object.entries(timeRanges(t)).some(([index, range]) => {
      if (+index === selectedRange) {
        if (range.start < range.end) {
          // Normal range (e.g., morning, afternoon, evening)
          return hours >= range.start && hours < range.end;
        } else {
          // Range that spans across midnight (e.g., night)
          return hours >= range.start || hours < range.end;
        }
      }
      return false;
    });
  });

  if (timeSlots.length === 0) {
    return (
      <div>
        <p className='text-center text-lg text-secondary'>
          {t('no_timeslots')}
        </p>
      </div>
    );
  }

  return (
    <>
      <DayTimeSelector
        timeRanges={filteredTimeRanges}
        selectedIndex={selectedRange}
        setDayTime={setSelectedRange}
      />
      <div className='horizontal-scrollbar mt-2 flex w-full gap-4 overflow-x-auto whitespace-nowrap pb-1 sm:max-w-pcScreen'>
        {filteredTimeSlots.map((timeSlot, i) => (
          <TimeSlot
            key={i}
            selected={
              timeSlot.id === timeSlotId || timeSlot.id === timeSlotIdOrder
            }
            timeSlot={new Date(timeSlot.startTime)}
            handleSelectTime={() => {
              handleSelectTime(new Date(timeSlot.startTime), timeSlot.id);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default TimeSlotsList;
