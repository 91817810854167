import React from 'react';

type SectionTitleProps = {
  title: string;
  description: string;
};

const SectionTitle = ({ description, title }: SectionTitleProps) => {
  return (
    <div>
      <h5 className='text-3xl leading-[3.2rem] text-gray-7 sm:mb-7 sm:text-center sm:text-[3.125rem]'>
        {title}
      </h5>
      <p className='font-inter italic text-gray-4 sm:text-center sm:text-[1.31rem] sm:leading-[1.968rem]'>
        {description}
      </p>
    </div>
  );
};

export default SectionTitle;
