import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button } from 'ui';

const NoCategoryFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className='flex flex-col items-center gap-3'>
      <p className='text-center text-lg text-gray-2'>
        Sorry, we couldn't find the category you were looking for
      </p>
      <Button
        className='w-1/2 py-3'
        variant='yellowBlackStroke'
        onClick={() => navigate(-1)}
      >
        {t('go_back')}
      </Button>
    </div>
  );
};

export default NoCategoryFound;
