import React, { useEffect, useState } from 'react';
import { useApiRequest } from 'hooks/useApiRequest';
import { Review, SpecialistProfileDto } from 'utils/types';
import { SectionHeader, UserReview, Wrapper } from 'ui';
import { BASE_URL } from 'api/api';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import StarIcon from 'images/emoji/(star).svg';
import { useAppSelector } from 'hooks/useAppSelector';

type ReviewsResponse = {
  data: Review[];
  pagination: {
    totalElements: number;
    totalPages: number;
    page: number;
    size: number;
  };
};

const ReviewsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(0);

  const [specialist, setSpecialist] = useState<SpecialistProfileDto | null>(
    null,
  );

  const { isLoggedIn } = useAppSelector((state) => state.user);
  const { specialist: specialistOrder } = useAppSelector(
    (state) => state.createOrderPage,
  );

  const { data: reviewsObj } = useApiRequest<ReviewsResponse>({
    endpoint: `${BASE_URL}specialists/${id}/reviews?page=${currentPage}&size=10`,
    deps: currentPage,
  });
  const { data: specialistFetched, loading } =
    useApiRequest<SpecialistProfileDto>({
      conditionToFetch: isLoggedIn && !specialist?.rating,
      endpoint: `${BASE_URL}specialists/${id}`,
      destructuringDataType: 'profileDto',
    });

  const pages = [currentPage - 1, currentPage, currentPage + 1].filter(
    (page) => page >= 0 && page < (reviewsObj?.pagination?.totalPages ?? 0),
  );

  const handlePageChange = (page: number) => {
    if (page < 0 || page > (reviewsObj?.pagination?.totalPages ?? 0) - 1) {
      return;
    }
    setCurrentPage(page);
  };

  useEffect(() => {
    if (!loading && specialistFetched) {
      setSpecialist(specialistFetched);
    }
    if (specialistOrder.id) {
      setSpecialist(specialistOrder);
    }
  }, [loading, specialist?.id, specialistFetched, specialistOrder]);

  return (
    <Wrapper flexColumnSpaceBetween>
      <div>
        <SectionHeader
          title={specialist?.firstName || ''}
          subtitle={
            <div className='flex items-center justify-center'>
              <img alt='star icon' src={StarIcon} className='mr-2' />
              <span>
                {!loading
                  ? `${specialist?.rating || ''} • ${
                      reviewsObj?.pagination?.totalElements || 0
                    } ${t('order.specialist_found.reviews')}`
                  : ''}
              </span>
            </div>
          }
        />
        <ul className='my-4 overflow-y-auto sm:max-h-[600px]'>
          {reviewsObj?.data?.map((review, index) => (
            <React.Fragment key={review.ts}>
              <UserReview review={review} />
              {index < reviewsObj?.data?.length - 1 && (
                <div className=' my-9 h-[1px] w-full bg-[#DADADA]' />
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
      {!!reviewsObj?.pagination.totalElements && (
        <div className='flex items-center justify-center gap-4'>
          <div className='inline-block'>
            <FaChevronLeft
              className={`cursor-pointer transition-all active:p-1 ${
                currentPage + 1 === 1 ? 'opacity-0' : ''
              }`}
              size={16}
              onClick={() => handlePageChange(currentPage - 1)}
            />
          </div>
          {pages.map((page) => (
            <div
              key={page}
              className={`cursor-pointer text-xl ${
                page === currentPage ? 'text-[#FFCA0F]' : ''
              }`}
              onClick={() => handlePageChange(page)}
            >
              <span className='text-xl'>{page + 1}</span>
            </div>
          ))}
          <div className='inline-block'>
            <FaChevronRight
              className={`cursor-pointer transition-all active:p-1 ${
                currentPage + 1 > reviewsObj?.pagination?.totalPages! - 1 ||
                loading
                  ? 'text-gray-3'
                  : ''
              }  ${
                currentPage + 1 === reviewsObj?.pagination?.totalPages
                  ? 'opacity-0'
                  : ''
              }`}
              size={15}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default ReviewsPage;
