import React from 'react';
import { OrderWithCoordinates } from 'utils/types';
import { OrderWithMapCard } from 'ui';
import { getTypedStorageItem } from 'utils/storage';

type ShortInfoOrderItemProps = {
  orderItem: OrderWithCoordinates;
  isLoadedMap: boolean;
};

const ShortInfoOrderItem = ({
  orderItem,
  isLoadedMap,
}: ShortInfoOrderItemProps) => {
  const locale = getTypedStorageItem('selectedLanguage') || 'en';
  const date = new Date(orderItem.day);
  const formattedDay = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date);

  return (
    <div className='flex flex-col gap-3 pr-2'>
      <h2 className='-mb-2'>{formattedDay}</h2>
      {orderItem.orders.map((order) => {
        return (
          <OrderWithMapCard
            order={order}
            isLoadedMap={isLoadedMap}
            url={`/orders/${order.idStr}`}
            key={order.idStr}
          />
        );
      })}
    </div>
  );
};

export default ShortInfoOrderItem;
