import React from 'react';
import { withModalHandling } from './withModalHandling';
import { Button, ModalWrapper } from 'ui';
import { FaChevronLeft, FaCircle } from 'react-icons/fa6';
import Webcam from 'react-webcam';

type CameraModalProps = {
  handleCloseModal: () => void;
  hasCameraPermission: boolean;
  capturePhoto: () => void;
  webcamRef: React.RefObject<Webcam>;
  description: string;
};

const CameraModal = ({
  handleCloseModal,
  webcamRef,
  hasCameraPermission,
  description,
  capturePhoto,
}: CameraModalProps) => {
  return (
    <ModalWrapper
      modalId='camera'
      className='!h-[100vh] justify-between  !bg-transparent '
    >
      <div className='h-full'>
        <FaChevronLeft
          color='#fff'
          title='Close modal'
          size={40}
          onClick={handleCloseModal}
          className='-ml-10 -mt-5 mr-auto cursor-pointer active:scale-95'
        />

        {hasCameraPermission ? (
          <>
            <Webcam
              audio={false}
              
              ref={webcamRef}
              screenshotFormat='image/jpeg'
              className='mx-auto rounded-[20px] sm:h-[50vh]'
            />
            <p className='my-2 text-center text-sm text-white'>
              Point the camera at your face
            </p>
            <p className='text-center text-xl text-accent'>{description}</p>
          </>
        ) : (
          <div className='flex h-full items-center justify-center'>
            <p className='text-center text-xl text-white'>
              Please allow access to your camera
            </p>
          </div>
        )}
      </div>

      {hasCameraPermission ? (
        <FaCircle
          title='Take a photo'
          onClick={capturePhoto}
          className='cursor-pointer rounded-full border-4 border-white p-1 text-white active:scale-95'
          size={80}
        />
      ) : (
        <Button variant='black' className='w-1/3' onClick={handleCloseModal}>
          Close
        </Button>
      )}
    </ModalWrapper>
  );
};

export default withModalHandling(CameraModal, 'camera');
