import React, { useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { setUserRole } from 'redux/userSlice';
import { Button, Dropdown, ToggleChevron } from 'ui';

const LoginButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useOnclickOutside(() => {
    if (showDropdown) setShowDropdown(false);
  });

  const handleLogin = (role: 'client' | 'specialist') => {
    dispatch(setUserRole(role));
    setShowDropdown(false);
    navigate('/login');
  };

  return (
    <div ref={dropdownRef} className='relative'>
      <Button
        onClick={() => setShowDropdown(!showDropdown)}
        variant='yellowStroke'
        className='inline-flex items-center justify-between gap-2 !border-black !bg-[#FAE18B] !py-3 hover:!bg-accent-80'
      >
        {t('log_in.name')}

        <ToggleChevron isOpen={showDropdown} size={15} />
      </Button>
      <Dropdown show={showDropdown} position='left'>
        <Button
          variant='text'
          onClick={() => handleLogin('client')}
          className='w-full text-left hover:underline'
        >
          {t('login_as_customer')}
        </Button>
        <Button
          variant='text'
          onClick={() => handleLogin('specialist')}
          className='w-full hover:underline'
        >
          {t('login_as_specialist')}
        </Button>
      </Dropdown>
    </div>
  );
};

export default LoginButton;
