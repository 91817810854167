import React from 'react';
import { useNavigate } from 'react-router';
import Logo from 'images/icons/logo.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import ProfileMenuButton from './ProfileMenuButton';
import { LanguageDropDown } from 'ui';
import { logOut } from 'api/login';
import LoginButton from 'components/Main/LoginButton';

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, uid, role } = useAppSelector((state) => state.user);

  const handleLogOut = async () => {
    await logOut(uid, role as 'client' | 'specialist', dispatch, navigate);
  };

  const link = isLoggedIn
    ? role === 'client'
      ? '/category'
      : '/specialist/profile'
    : '/';

  return (
    <header className='flex h-[8dvh] w-full items-center bg-white sm:z-50 sm:h-[9dvh] sm:shadow-md'>
      <nav className='flex w-full items-center justify-between px-4 lg:px-6'>
        <a href={link} className='flex items-center'>
          <img
            src={Logo}
            className='h-9'
            width='36'
            height='36'
            alt='GoTou Logo'
            draggable={false}
          />
        </a>
        <div className='flex items-center justify-between'>
          <ul className='mr-3 flex items-center gap-4'>
            <LanguageDropDown />
            {!isLoggedIn && <LoginButton />}
          </ul>
          {isLoggedIn && <ProfileMenuButton handleLogOut={handleLogOut} />}
        </div>
      </nav>
    </header>
  );
};

export default Header;
