import React from 'react';
import { useTranslation } from 'react-i18next';
import HeroImg from 'images/main-specialist/hero image.webp';
import { motion } from 'framer-motion';
import { Button } from 'ui';
import ArrowIcon from 'images/main-specialist/Updating Arrows.svg';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUserRole } from 'redux/userSlice';

const HeroSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogin = () => {
    dispatch(setUserRole('specialist'));
    navigate('/login');
  };
  return (
    <section className='main-page-wrapper mx-auto flex max-w-[1440px] flex-col items-center gap-10 md:mt-10 md:max-w-none lg:flex-row lg:items-start lg:justify-center lg:gap-5'>
      <div className='flex flex-col gap-8'>
        <h1 className='text-3xl sm:text-5xl sm:leading-[3.4rem] xl:text-[4rem] xl:leading-[6rem]'>
          <span className='italic text-accent'>
            {t('businessPage.hero.title.span1')}{' '}
          </span>
          {t('businessPage.hero.title.span2')}{' '}
          <span className='italic text-accent'>
            {t('businessPage.hero.title.span3')}{' '}
          </span>
        </h1>
        <p className='text-gray-4 sm:text-lg' style={{ fontFamily: 'Inter' }}>
          {t('businessPage.hero.description')}
        </p>
        <div className='w-fit'>
          <div className='relative w-fit'>
            <Button onClick={handleLogin} variant='yellowBlackStroke'>
              {t('businessPage.buttons.start')}
            </Button>
            <img
              src={ArrowIcon}
              alt=''
              className='absolute -bottom-[80%] -right-[85px] hidden lg:block'
            />
          </div>
          <p className='mt-2 text-gray-8 lg:mt-5'>
            {t('businessPage.hero.tip')}
          </p>
        </div>
      </div>
      <motion.img
        src={HeroImg}
        alt='Different people'
        className='aspect-[4/3] max-h-[540px] w-[80vw] object-contain lg:w-[40vw]'
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
      />
    </section>
  );
};

export default HeroSection;
