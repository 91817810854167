import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from 'hooks/useAppDispatch';
import VerificationForm from 'components/Login/VerificationForm';
import PhoneInputForm from 'components/Login/PhoneInputForm';
import { SectionHeader, Wrapper } from 'ui';
import { validatePhoneNumber } from 'utils/validations';
import { useAppSelector } from 'hooks/useAppSelector';
import { resendCode, sentCode, verifyCode } from 'api/login';
import { useTranslation } from 'react-i18next';
import { setReferralCode, setUserRole } from 'redux/userSlice';

const LoginPage = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [uid, setUid] = useState('');
  const [isValid, setIsValid] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { role, isLoggedIn, referralCode, specSubRole } = useAppSelector(
    (state) => state.user,
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const referralCodeByParam = searchParams.get('ref');

  const handleLoginUser = async (
    codeSMS: string,
    e?: React.FormEvent<HTMLFormElement>,
  ) => {
    if (e) e.preventDefault();

    if (role === 'guest') {
      return navigate('/');
    }

    setIsLoading(true);
    await verifyCode(
      uid,
      phoneNumber,
      role,
      codeSMS,
      dispatch,
      navigate,
      setIsValid,
      referralCode,
    );
    setIsLoading(false);
  };

  const handleSentCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validatePhoneNumber(phoneNumber)) {
      setIsValid(true);
      if (role === 'guest') {
        dispatch(setUserRole('client'));
      }
      const newRole = role === 'guest' ? 'client' : role;

      setIsLoading(true);
      await sentCode(phoneNumber, newRole, setUid, dispatch, referralCode);
      setIsLoading(false);
    } else setIsValid(false);
  };

  const handleResendCode = () => {
    if (role === 'guest') return navigate('/');
    resendCode(uid, setIsValid, role);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isLoggedIn && referralCodeByParam && role === 'client') {
      dispatch(setReferralCode(referralCodeByParam));
      navigate(`/create-order?ref=${referralCodeByParam}`);
      return;
    }
    if (isLoggedIn && referralCodeByParam && role === 'specialist') {
      if (referralCode === referralCodeByParam) {
        navigate(`/specialist/create-order`);
        return;
      } else {
        navigate(`/specialist/profile?ref=${referralCodeByParam}`);
        return;
      }
    }
    if (isLoggedIn && role === 'client') {
      navigate('/category');
      return;
    }
    if (
      isLoggedIn &&
      role === 'specialist' &&
      specSubRole !== 'acceptingTerms'
    ) {
      navigate('/specialist/profile');
      return;
    }
    if (referralCodeByParam && !isLoggedIn) {
      dispatch(setUserRole('client'));
      dispatch(setReferralCode(referralCodeByParam));
      navigate(`/create-order?ref=${referralCodeByParam}`);
    }
  }, [
    isLoggedIn,
    referralCode,
    navigate,
    dispatch,
    role,
    referralCodeByParam,
    specSubRole,
  ]);

  return (
    <Wrapper flexColumnSpaceBetween>
      <SectionHeader
        title={
          role === 'specialist'
            ? t('login_as_specialist')
            : t('login_as_customer')
        }
        subtitle={uid ? t('log_in.sms') : t('log_in.text_1')}
        wrapperClassName='absolute z-20'
        showGoBackButton
      />
      {uid ? (
        <VerificationForm
          isValid={isValid}
          setIsValid={setIsValid}
          isLoading={isLoading}
          handleLoginUser={handleLoginUser}
          handleResendCode={handleResendCode}
        />
      ) : (
        <PhoneInputForm
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          isLoading={isLoading}
          isValid={isValid}
          handleSentCode={handleSentCode}
        />
      )}
    </Wrapper>
  );
};

export default LoginPage;
