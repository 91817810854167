import React from 'react';
import Logo from 'images/icons/logo.svg';

type ReviewCardProps = {
  name: string;
  avatar?: string;
  time: string;
  reviewTitle?: string;
  reviewImg?: string;
  review: string;
};

const ReviewCard = ({
  avatar,
  review,
  reviewTitle,
  name,
  time,
  reviewImg,
}: ReviewCardProps) => {
  return (
    <div className='h-full w-[90vw] max-w-[300px] overflow-y-auto rounded-[20px] border border-main.border p-6 font-inter md:w-[35vh] md:min-w-[420px] md:max-w-[420px] xs:w-[75vw]'>
      <div className='mb-4 flex items-center'>
        <img
          src={avatar || Logo}
          alt='Reviewer'
          loading='lazy'
          className='mr-4 h-12 w-12 rounded-full'
        />
        <div>
          <h4 className='text-lg '>{name}</h4>
          <p className='text-[#BEBEBE]'>{time}</p>
        </div>
      </div>
      {reviewTitle && <h5 className='text-xl3'>{reviewTitle}</h5>}
      {reviewImg && (
        <img
          src={reviewImg}
          alt='User review'
          className='rounded-[20px] object-cover'
        />
      )}
      <p className='text-md2 text-[#071330B2] '>{review}</p>
    </div>
  );
};

export default ReviewCard;
