import React from 'react';
import { Order } from 'utils/types';
import { useNavigate } from 'react-router-dom';
import { OrderTimeRange } from 'ui';
import { formatCurrency } from 'utils/validations';
import { useTranslation } from 'react-i18next';

type TimeSpecificOrderButtonProps = {
  order: Order['orders'][number];
  url: string;
  orderDay: string;
};

const DetailedArchiveOrderButton = ({
  order,
  url,
  orderDay,
}: TimeSpecificOrderButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className='flex cursor-pointer flex-col gap-2 rounded-lg border border-gray-3 p-2 transition-all hover:border-accent'
      onClick={() => navigate(url)}
    >
      <div className='flex items-center justify-between'>
        <p className='text-gray-1'>{orderDay}</p>
      </div>
      <div className='flex justify-between'>
        <div className='flex  flex-col justify-between !whitespace-normal text-left'>
          <p className='text-[#989898CC]'>{order.serviceName}</p>
          <p className='text-[#989898CC]'>{t('create_order.price')}</p>
        </div>
        <div className='flex flex-col justify-between !whitespace-normal text-left'>
          <OrderTimeRange
            dur={order.dur}
            wrapperClassName='!text-gray-1'
            timeOfOrder={order.timeOfOrder}
          />
          <span className='!whitespace-nowrap text-gray-1'>
            {formatCurrency(order.currency, order.price)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DetailedArchiveOrderButton;
