import React, { useId } from 'react';

type CheckboxProps = {
  children: React.ReactNode;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
};

const Checkbox = ({
  children,
  checked,
  onChange,
  required,
  disabled,
}: CheckboxProps) => {
  const id = useId();
  return (
    <div className='mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]'>
      <input
        className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded border-2 border-solid border-accent-80 outline-accent before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:content-[''] checked:bg-accent-80 checked:before:opacity-[0.16]  checked:after:absolute  checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent disabled:cursor-default disabled:border-0 disabled:bg-gray-3"
        type='checkbox'
        checked={checked}
        id={id}
        required={required}
        onChange={onChange}
        disabled={disabled}
      />
      <label
        className={`inline-block pl-[0.15rem] ${
          disabled ? 'text-secondary' : 'cursor-pointer text-primary'
        }`}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
