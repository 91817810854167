import React from 'react';
import Spinner from './Spinner';
import 'styles/button.css';

type ButtonProps = {
  children: React.ReactNode;
  variant?:
    | 'primary'
    | 'text'
    | 'yellow'
    | 'yellowBlackStroke'
    | 'yellowStroke'
    | 'whiteStroke'
    | 'grayStroke'
    | 'black'
    | 'outline-botox';

  type?: 'button' | 'submit' | 'reset';
  skeleton?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  size?: 'sm' | 'md' | 'lg';
  form?: string;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled,
      onClick,
      className,
      form,
      loading,
      variant = 'primary',
      type = 'button',
      size = 'md',
      skeleton = false,
    },
    ref,
  ) => {
    const buttonClassName = `button ${variant} ${size} ${className}`;

    if (skeleton) {
      const skeletonClassName = ` 
        animate-pulse rounded bg-secondary text-transparent ${
          variant === 'primary' ? '' : '-50px'
        } py-2 ${variant === 'primary' ? 'px-4' : 'px-16'}  ${className} `;

      return (
        <button className={skeletonClassName} disabled>
          {children}
        </button>
      );
    }

    return (
      <button
        ref={ref}
        className={buttonClassName}
        disabled={disabled || loading}
        type={type}
        onClick={onClick}
        form={form}
      >
        {loading ? <Spinner size={28} className='mx-auto' /> : children}
      </button>
    );
  },
);

export default Button;
