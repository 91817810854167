import React from 'react';
import { GoBackButton } from 'ui';

type SectionHeaderProps = {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  wrapperClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  showGoBackButton?: boolean;
  goBackButtonClassName?: string;
  center?: boolean;
};

const SectionHeader = ({
  title,
  subtitle,
  wrapperClassName,
  titleClassName,
  subtitleClassName,
  goBackButtonClassName,
  showGoBackButton = true,
  center = true,
}: SectionHeaderProps) => {
  return (
    <section
      className={`relative flex w-full flex-row items-center gap-2 smMax:mt-1 ${wrapperClassName}`}
    >
      {showGoBackButton && (
        <GoBackButton className={`absolute top-0 ${goBackButtonClassName}`} />
      )}
      <div className={`flex-grow pl-9 ${center ? 'mx-auto' : ''}`}>
        {title &&
          (typeof title === 'string' ? (
            <h2
              className={`${center ? 'text-center' : ''} ${
                titleClassName || ''
              }  text-2xl font-medium`}
            >
              {title}
            </h2>
          ) : (
            <div
              className={`${titleClassName || ''} ${
                center ? 'text-center' : ''
              } text-2xl font-medium`}
            >
              {title}
            </div>
          ))}
        {subtitle &&
          (typeof subtitle === 'string' ? (
            <p
              className={`mt-1 ${
                center ? 'text-center' : ''
              } text-sm text-secondary ${subtitleClassName || ''}`}
            >
              {subtitle}
            </p>
          ) : (
            <div
              className={`mt-1 ${
                center ? 'text-center' : ''
              } text-sm text-secondary ${subtitleClassName || ''}`}
            >
              {subtitle}
            </div>
          ))}
      </div>
    </section>
  );
};

export default SectionHeader;
