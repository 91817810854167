import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { Button, Input } from 'ui';
import { PHONE_INPUT_COUNTRIES } from 'utils/constants';
import VerificationInput from 'react-verification-input';
import { validatePhoneNumber } from 'utils/validations';
import { sentCode } from 'api/login';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { AppDispatch } from 'store';
import { useAppSelector } from 'hooks/useAppSelector';
import { useLocation } from 'react-router';
import { getTypedStorageItem } from 'utils/storage';

type RegisterFormProps = {
  handleLoginUser: (
    setIsLoading: (value: boolean) => void,
    data: {
      uid: string;
      phoneNumber: string;
      role: 'client' | 'specialist';
      codeSMS: string;
      dispatch: AppDispatch;
      setIsValid: (boolean: boolean) => void;
      firstName: string;
      lastName: string;
    },
  ) => void;
  disabled?: boolean;
};

const RegisterForm = ({ handleLoginUser, disabled }: RegisterFormProps) => {
  const { t } = useTranslation();
  const { referralCode } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [uid, setUid] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [codeSMS, setCodeSMS] = useState('');

  const searchParams = new URLSearchParams(search);
  const referralCodeByParam = searchParams.get('ref');

  const isDisabled = !phoneNumber || !firstNameInput || !lastNameInput;

  const handleSentCode = async () => {
    if (validatePhoneNumber(`+${phoneNumber}`)) {
      setIsValid(true);
      setIsLoading(true);
      const refCode = referralCode ? referralCode : referralCodeByParam;
      await sentCode(`+${phoneNumber}`, 'client', setUid, dispatch, refCode);
      setIsLoading(false);
    } else setIsValid(false);
  };

  const handleContinue = async () => {
    if (uid) {
      if (!codeSMS) toast.error(t('enter_code'));
      await handleLoginUser(setIsLoading, {
        uid,
        phoneNumber: `+${phoneNumber}`,
        role: 'client',
        codeSMS,
        dispatch,
        setIsValid,
        firstName: firstNameInput,
        lastName: lastNameInput,
      });
    } else {
      await handleSentCode();
    }
  };
  const userCountryIP = getTypedStorageItem('country')?.toLowerCase() || '';
  const defaultCountry = PHONE_INPUT_COUNTRIES.includes(userCountryIP)
    ? userCountryIP
    : 'ch';

  const handleChangeInput = (value: string) => {
    setCodeSMS(value);
  };

  return (
    <form className='flex h-full flex-col justify-between gap-2'>
      <div className='space-y-3'>
        <Input
          variant='outline'
          label={t('edit.profile_first_name')}
          required
          value={firstNameInput}
          onChange={(e) => {
            const newValue = e.target.value;
            if (/^[^\p{P}\p{S}]*$/u.test(newValue)) {
              setFirstNameInput(newValue);
            }
          }}
        />
        <Input
          variant='outline'
          label={t('edit.profile_last_name')}
          required
          value={lastNameInput}
          onChange={(e) => {
            const newValue = e.target.value;
            if (/^[^\p{P}\p{S}]*$/u.test(newValue)) {
              setLastNameInput(newValue);
            }
          }}
        />
        <PhoneInput
          country={defaultCountry}
          onlyCountries={PHONE_INPUT_COUNTRIES}
          placeholder={t('edit_profile.phone_number')}
          inputClass={`hover:border-accent focus:border-accent ${
            !isValid && 'error'
          }`}
          value={phoneNumber}
          inputProps={{
            required: true,
            style: {
              fontSize: '24px',
            },
          }}
          onChange={setPhoneNumber}
        />
        {!isValid && <p className='text-error'>{t('valid_phone_number')}</p>}

        {uid && (
          <div className='rounded-lg border border-secondary px-2 py-4'>
            <label
              className={`label-top pointer-events-none block select-none text-sm text-secondary`}
            >
              {t('log_in.sms_code')}
            </label>
            <VerificationInput
              validChars='0-9'
              inputProps={{
                inputMode: 'numeric',
                autoComplete: 'one-time-code',
                style: {
                  WebkitBoxShadow: '0 0 0px 0px white inset',
                  boxShadow: '0 0 0px 0px white inset',
                  WebkitTextFillColor: 'transparent',
                },
              }}
              placeholder=''
              length={6}
              classNames={{
                container: 'border-0 h-[24px] w-full',
                character: `border-0 border-b-2 h-[30px] text-xl text-center ${
                  isValid ? '' : 'border-error'
                } `,
                characterInactive: 'outline-none bg-white text-primary',
                characterSelected: 'outline-none border-accent text-primary',
              }}
              value={codeSMS}
              onChange={handleChangeInput}
              autoFocus={true}
            />
          </div>
        )}
      </div>

      <Button
        className='mt-4 w-full py-4'
        type='submit'
        variant='yellow'
        loading={isLoading}
        disabled={isDisabled || disabled}
        onClick={handleContinue}
      >
        {t('save')}
      </Button>
    </form>
  );
};

export default RegisterForm;
