import React from 'react';
import { motion } from 'framer-motion';

type DayTimeSelectorProps = {
  timeRanges: {
    index: number;
    start: number;
    end: number;
    label: string;
  }[];
  selectedIndex: number;
  setDayTime: (index: number) => void;
};

const DayTimeSelector = ({
  setDayTime,
  timeRanges,
  selectedIndex,
}: DayTimeSelectorProps) => {
  if (timeRanges.length === 1) return <></>;

  return (
    <div className='scrollbar-day-time mx-auto flex w-full max-w-fit items-center justify-between overflow-x-auto whitespace-nowrap rounded-[13px] bg-gray-10 px-[3px] py-[3px]'>
      {timeRanges.map((timeRange) => (
        <motion.div
          key={timeRange.index}
          className={`inline-block cursor-pointer rounded-[10px] px-4 py-2 ${
            timeRange.index === selectedIndex
              ? 'bg-accent font-medium text-white'
              : 'text-secondary'
          }`}
          onClick={() => setDayTime(timeRange.index)}
          whileTap={{ scale: 0.97 }}
        >
          {timeRange.label}
        </motion.div>
      ))}
    </div>
  );
};

export default DayTimeSelector;
