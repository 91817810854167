import { useEffect, useState, MutableRefObject } from 'react';

const useInMiddleOfScreen = (
  ref: MutableRefObject<HTMLElement | null>,
): boolean => {
  const [isInMiddle, setIsInMiddle] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const middle = window.innerHeight / 1.5;
        setIsInMiddle(rect.top <= middle && rect.bottom >= middle);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref]);

  return isInMiddle;
};

const useAtSeventyPercentOfScreen = (
  ref: MutableRefObject<HTMLElement | null>,
): boolean => {
  const [isAtEightyPercent, setIsAtEightyPercent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const eightyPercent = window.innerHeight * 0.7;
        setIsAtEightyPercent(
          rect.top <= eightyPercent && rect.bottom >= eightyPercent,
        );
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref]);

  return isAtEightyPercent;
};

export { useInMiddleOfScreen, useAtSeventyPercentOfScreen };
