import React, { useEffect } from 'react';
import PresentIcon from 'images/emoji/Challenge.svg';
import { useApiRequest } from 'hooks/useApiRequest';
import { BASE_URL } from 'api/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { setTrialEndDate } from 'redux/specialistSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';

const TrialBadge = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { trialEndDate } = useAppSelector((state) => state.specialist);

  const { data: ts } = useApiRequest<number>({
    conditionToFetch: !trialEndDate,
    endpoint: `${BASE_URL}specialists/getTrialEndDate`,
    destructuringDataType: 'ts',
  });

  useEffect(() => {
    if (ts) {
      dispatch(setTrialEndDate(ts));
    }
  }, [dispatch, ts]);

  if (!trialEndDate) return <></>;

  const time = new Date(trialEndDate).toLocaleString('de-DE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  return (
    <div className='flex items-center gap-1 rounded-lg bg-[#FFE27A99] px-3 py-2 xs:mr-auto xs-min:ml-auto'>
      <img src={PresentIcon} alt='present icon' width={17} height={17} />
      <p className='text-base2'>
        {t('trialPage.trial_until')} {time}
      </p>
    </div>
  );
};

export default TrialBadge;
