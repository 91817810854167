import React, { useEffect, useRef } from 'react';
import {
  useAtSeventyPercentOfScreen,
  useInMiddleOfScreen,
} from 'hooks/useScreenHeight';
import { useInView, motion } from 'framer-motion';

type GuideItemProps = {
  order: number;
  title: string;
  description: string;
  imgSrc: string;
  setCurrentImg: (imgSrc: string) => void;
};

const GuideItem = ({
  order,
  title,
  description,
  imgSrc,
  setCurrentImg,
}: GuideItemProps) => {
  const ref = useRef(null);

  const isInView = useInView(ref);
  const isInMiddle = useInMiddleOfScreen(ref);
  const isAtEightyPercent = useAtSeventyPercentOfScreen(ref);

  useEffect(() => {
    if (order === 1 && isInView && isAtEightyPercent) {
      setCurrentImg(imgSrc);
    } else if (order === 2 && isInMiddle) {
      setCurrentImg(imgSrc);
    } else if (order === 3 && isAtEightyPercent) {
      setCurrentImg(imgSrc);
    }
  }, [imgSrc, isAtEightyPercent, isInMiddle, isInView, order, setCurrentImg]);

  return (
    <li
      ref={ref}
      className='flex flex-col items-center gap-y-14 md:flex-row md:items-center md:py-48'
    >
      <div className='flex flex-col gap-[10px]'>
        <p className='text-left text-xl leading-8 text-accent'>0{order}</p>
        <h3 className='text-[2rem] leading-8'>{title}</h3>
        <p className='font-inter text-md2 leading-8 text-[#3C3E3899]'>
          {description}
        </p>
      </div>

      <motion.img
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        src={imgSrc}
        alt='App screenshot'
        className='aspect-[185/218] h-96 w-full object-contain md:hidden'
      />
    </li>
  );
};

export default GuideItem;
