import React from 'react';

type ReasonItemProps = {
  order: number;
  title: string;
};

const ReasonItem = ({ order, title }: ReasonItemProps) => {
  return (
    <li className='flex gap-5 xs:gap-2'>
      <span className='font-sans text-xl3 text-accent'>0{order}</span>
      <h4 className='ml-8 font-inter text-[1.31rem] leading-8 text-[#3C3E38B2]'>
        {title}
      </h4>
    </li>
  );
};

export default ReasonItem;
