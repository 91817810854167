import React from 'react';

type SectionTitleProps = {
  title: string;
  subtitle: string;
  wrapperClassName?: string;
  titleClassName?: string;
  subtitleClassName?: string;
};

const SectionTitle = ({
  title,
  subtitle,
  wrapperClassName,
  titleClassName,
  subtitleClassName,
}: SectionTitleProps) => {
  return (
    <section className={`flex w-full flex-col ${wrapperClassName}`}>
      <h5 className={`${titleClassName || ''}  text-2xl font-medium`}>
        {title}
      </h5>

      <p className={`mt-1 text-sm text-secondary ${subtitleClassName || ''}`}>
        {subtitle}
      </p>
    </section>
  );
};

export default SectionTitle;
