import React from 'react';
import { useTranslation } from 'react-i18next';
import FeatureCard from './FeatureCard';

type Features = {
  title: string;
  description: string;
}[];

const ServiceFeaturesSection = () => {
  const { t } = useTranslation();

  return (
    <section className='main-page-wrapper flex max-w-[1440px] flex-col gap-5 lg:flex-row lg:gap-2 xl:gap-8'>
      {(t('hairdresserPage.features', { returnObjects: true }) as Features).map(
        ({ title, description }, i) => (
          <FeatureCard
            key={i}
            order={i + 1}
            title={title}
            description={description}
          />
        ),
      )}
    </section>
  );
};

export default ServiceFeaturesSection;
