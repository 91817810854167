import React, { useId } from 'react';

type RadioProps = {
  label?: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  radioClassName?: string;
  labelClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const Radio = ({
  label,
  value,
  name,
  checked = false,
  disabled = false,
  required,
  onChange,
  radioClassName,
  labelClassName,
}: RadioProps) => {
  const id = useId();

  return (
    <div className='mb-4 flex items-center'>
      <input
        required={required}
        id={id}
        type='radio'
        name={name}
        value={value}
        defaultChecked={checked}
        disabled={disabled}
        onChange={onChange}
        className={radioClassName}
      />
      <label htmlFor={id} className={`ms-2 ${labelClassName}`}>
        {label}
      </label>
    </div>
  );
};

export default Radio;
