import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Schedule } from 'utils/types';
import { logOutUser } from './actions';

type ScheduleState = {
  schedule: Schedule[];
};

const initialState: ScheduleState = {
  schedule: [],
};

const scheduleSlice = createSlice({
  name: 'specialistShedule',
  initialState,
  reducers: {
    setSchedule: (state, { payload }: PayloadAction<Schedule[]>) => {
      state.schedule = payload;
    },
    resetSchedule: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(logOutUser, () => initialState);
  },
});

export const { setSchedule, resetSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;
