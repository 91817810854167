import React from 'react';
import SectionTitle from './SectionTitle';
import { Button } from 'ui';
import AppScreenShot from 'images/main-specialist/new order.png';
import Logo from 'images/icons/Logo-goto.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';
import { setUserRole } from 'redux/userSlice';
import { useTranslation } from 'react-i18next';
import { getTypedStorageItem } from 'utils/storage';

const ReadyStartSection = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogin = () => {
    dispatch(setUserRole('specialist'));
    navigate('/login');
  };

  const isWideTextLanguage = getTypedStorageItem('selectedLanguage') === 'de';

  return (
    <section className='main-page-wrapper mb-12 w-full max-w-[1440px] py-12'>
      <SectionTitle
        title={t('businessPage.starts.title')}
        description={t('businessPage.starts.description')}
      />
      <div className='relative mt-10 w-full rounded-[20px] border border-accent bg-[#ECC4A73D] px-12 pb-36 pt-24 md:mr-auto md:mt-20 md:w-[85%]'>
        <div className='flex flex-col items-center justify-center gap-9 lg:max-w-[60%]'>
          <p className='text-center text-4xl'>
            {t('businessPage.starts.startToday')}
          </p>
          <p
            className={`absolute -bottom-2 select-none whitespace-nowrap font-sans leading-[1] text-[#FFFFFF66] sm:text-[4rem] md:text-[5rem] lg:-bottom-3 ${
              isWideTextLanguage
                ? 'text-[2.5rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem]'
                : 'text-[3.2rem] sm:text-[4rem] md:text-[5rem] lg:text-[7rem]'
            }`}
            style={{
              WebkitTextStroke: '0.5px #FFC700',
            }}
          >
            {t('businessPage.buttons.business')}
          </p>
          <div className='flex flex-row justify-center gap-4 smMax:flex-col'>
            <Button
              onClick={handleLogin}
              variant='yellowBlackStroke'
              className='flex-1'
            >
              {t('businessPage.buttons.start')}
            </Button>
            <Button
              variant='whiteStroke'
              className='flex-1 !border-black py-3  hover:!border-accent md:py-4 lg:!px-11'
              onClick={handleLogin}
            >
              {t('businessPage.buttons.register')}
            </Button>
          </div>
        </div>
        <img
          src={AppScreenShot}
          loading='lazy'
          alt='App screenshot'
          className='absolute -right-10 top-1/2 z-[3] hidden -translate-y-1/2 transform opacity-95 lg:block'
        />
        <img
          src={Logo}
          alt='logo'
          loading='lazy'
          className='absolute -right-[27%] top-0 z-[2] hidden translate-y-1/2 rotate-90 lg:block lg:h-[200px] lg:w-[450px]'
        />
      </div>
    </section>
  );
};

export default ReadyStartSection;
