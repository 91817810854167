import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import ua from './locales/ua.json';
import de from './locales/de.json';
import pl from './locales/pl.json';
import { getTypedStorageItem } from 'utils/storage';

const resources = {
  en: {
    translation: en,
  },
  ua: {
    translation: ua,
  },
  de: {
    translation: de,
  },
  pl: {
    translation: pl,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getTypedStorageItem('selectedLanguage') || 'en', // default language
  fallbackLng: 'en', // fallback language when a translation isn't found
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
