import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useAppSelector } from './hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setIsNewUser } from 'redux/userSlice';
import { Roles, SpecSubRole, ClientSubRole } from 'utils/types';

type AuthProps = {
  allowedRoles: (Roles | ClientSubRole | SpecSubRole)[];
};

const Auth = ({ allowedRoles }: AuthProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    role,
    isLoggedIn,
    firstName,
    address,
    city,
    specSubRole,
    clientSubRole,
  } = useAppSelector((state) => state.user);
  const { acceptedTerms } = useAppSelector((state) => state.specialist);
  const { schedule } = useAppSelector((state) => state.specialistSchedule);

  useEffect(() => {
    const hasEnteredData = firstName && address && city;
    if (role === 'client') {
      dispatch(setIsNewUser(!hasEnteredData));
    }
  }, [dispatch, role, firstName, address, city]);

  useEffect(() => {
    if (!allowedRoles.includes(role)) {
      navigate('/', { state: { from: location }, replace: true });
      return;
    }

    if (
      !city &&
      role === 'client' &&
      isLoggedIn &&
      location.pathname !== '/profile/edit'
    ) {
      navigate('/profile/edit');
      return;
    }

    if (specSubRole === 'payment' && location.pathname !== '/') {
      navigate('/specialist/payment');
      return;
    }

    if (specSubRole === 'editingProfile' && location.pathname !== '/') {
      navigate('/specialist/profile/edit');
      return;
    }

    if (specSubRole === 'editingSchedule' && location.pathname !== '/') {
      navigate('/specialist/schedule');
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowedRoles,
    city,
    isLoggedIn,
    location.pathname,
    navigate,
    role,
    schedule,
    acceptedTerms,
    specSubRole,
    clientSubRole,
  ]);

  return <Outlet />;
};

export default Auth;
