import React from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';
import { setUserRole } from 'redux/userSlice';
import ReactGA from 'react-ga';
import { useAppSelector } from 'hooks/useAppSelector';
import AcceptToast from 'modals/AcceptToast';
import { logOut } from 'api/login';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

type NavigationItemProps = {
  title: string;
  href: string;
  isVerticalLayout?: boolean;
  navigationRole?: 'client' | 'specialist';
  onClick?: () => void;
};

const NavigationItem = ({
  title,
  href,
  isVerticalLayout,
  navigationRole,
  onClick,
}: NavigationItemProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn, role, uid } = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const handleAcceptToast = async (
    messageKey: string,
    newRole: 'client' | 'specialist',
  ) => {
    AcceptToast({
      saveButtonText: t('menu.logout'),
      cancelButtonText: t('client.remove_favorite.no'),
      text: t(messageKey),
      handleSaveClick: async () => {
        await logOut(uid, role as 'client' | 'specialist', dispatch, navigate);
        dispatch(setUserRole(newRole));
        navigate('/login');
        toast.dismiss();
      },
      duration: 100000,
    });
  };

  const handleNavigation = (id: string) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Clicked Navigation Link',
      label: title,
    });

    if (onClick) onClick();

    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else {
      const isSpecialist = navigationRole === 'specialist';

      if (!isLoggedIn) {
        dispatch(setUserRole(isSpecialist ? 'specialist' : 'guest'));
        navigate(isSpecialist ? '/login' : '/category');
      } else {
        if (role === 'specialist' && !isSpecialist) {
          handleAcceptToast('message.login_specialist', 'client');
        } else if (role === 'client' && isSpecialist) {
          handleAcceptToast('message.login_client', 'specialist');
        } else {
          navigate(isSpecialist ? '/specialist/profile' : '/category');
        }
      }
    }
  };

  return (
    <li
      onClick={() => handleNavigation(href)}
      className={`${
        isVerticalLayout
          ? 'w-full border-b border-[#EDCAB1] hover:border-accent hover:text-accent'
          : ''
      }`}
    >
      <button className='font-sans text-md2 leading-8 hover:underline md:text-[1.31rem]'>
        {title}
      </button>
    </li>
  );
};

export default NavigationItem;
