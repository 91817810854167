import React, { useState } from 'react';
import { CgMenuRightAlt } from 'react-icons/cg';
import { IoCloseOutline } from 'react-icons/io5';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import toast from 'react-hot-toast';

import Navigation from './Navigation';
import { Button, LanguageDropDown } from 'ui';
import { setUserRole } from 'redux/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import ProfileMenuButton from 'components/header/ProfileMenuButton';
import { logOut } from 'api/login';
import { useAppSelector } from 'hooks/useAppSelector';
import AcceptToast from 'modals/AcceptToast';
import LoginButton from './LoginButton';
import LogoClient from 'images/main-page/logo-client.svg';

const navigationLinks = (t: any) => [
  {
    title: t('hairdresserPage.navigation.client'),
    navigationRole: 'client' as const,
    href: '/login',
  },
  {
    title: t('hairdresserPage.navigation.provider'),
    navigationRole: 'specialist' as const,
    href: '/login',
  },
  {
    title: t('hairdresserPage.navigation.faq'),
    href: 'faq',
  },
];

const MainHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { uid, role, isLoggedIn } = useAppSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const handleLogin = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Book now button',
      label: 'BotoxHeader',
    });

    if (!isLoggedIn || role !== 'specialist') {
      dispatch(setUserRole('client'));
      navigate('/category');
    } else {
      AcceptToast({
        saveButtonText: t('menu.logout'),
        cancelButtonText: t('client.remove_favorite.no'),
        text: t('message.login_specialist'),
        handleSaveClick: async () => {
          await logOut(
            uid,
            role as 'client' | 'specialist',
            dispatch,
            navigate,
          );
          dispatch(setUserRole('client'));
          navigate('/login');
          toast.dismiss();
        },
        duration: 100000,
      });
    }
  };

  const handleLogOut = async () => {
    await logOut(uid, role as 'client' | 'specialist', dispatch, navigate);
  };

  return (
    <header className='main-page-wrapper sticky left-0 top-0 z-50 max-h-[15vh] w-full bg-[#F6F5F4] px-5 md:px-[50px]'>
      <nav className='mx-auto flex items-center justify-between py-5'>
        <a href='/' className='flex items-center'>
          <img
            src={LogoClient}
            height={40}
            width={86}
            alt='GoTou Logo'
            draggable={false}
          />
        </a>
        <div className='flex items-center gap-5'>
          <div className='hidden xl:block'>
            <Navigation links={navigationLinks(t)} />
          </div>
          <div className='hidden sm:block md:ml-14'>
            <LanguageDropDown />
          </div>
          <div className='hidden justify-between gap-4 lg:flex'>
            <Button
              variant='text'
              className='hover:underline'
              onClick={() => navigate('/specialist')}
            >
              {t('businessPage.buttons.business')}
            </Button>
            {(!isLoggedIn || (isLoggedIn && role === 'specialist')) && (
              <Button
                onClick={handleLogin}
                variant='outline-botox'
                className='!py-2 !text-md2'
              >
                {t('hairdresserPage.buttons.book')}
              </Button>
            )}
            {!isLoggedIn && <LoginButton />}
          </div>
          {isLoggedIn && <ProfileMenuButton handleLogOut={handleLogOut} />}
          <div ref={ref} className='xl:hidden'>
            {isOpen ? (
              <IoCloseOutline
                size={40}
                className='-mr-1 cursor-pointer rounded-md p-1 transition-all hover:text-accent xl:hidden'
                onClick={() => setIsOpen(!isOpen)}
              />
            ) : (
              <CgMenuRightAlt
                size={30}
                className='scale-x-125 cursor-pointer rounded-md p-1 transition-all hover:text-accent xl:hidden'
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
            <div
              className={`transfrom absolute left-0 right-0 top-0 flex flex-col gap-12 rounded-b-[20px] bg-white px-[10%] pb-12 pt-20 transition-all duration-200 ease-in-out xs:px-[5%] ${
                isOpen
                  ? 'translate-y-0 opacity-100'
                  : 'pointer-events-none -translate-y-10 opacity-0'
              } -z-20`}
            >
              <Navigation
                links={navigationLinks(t)}
                onClick={() => setIsOpen(!isOpen)}
                isVerticalLayout
              />
              <LanguageDropDown disableAbsolutePositioning />
              <Button
                variant='text'
                className='mr-auto hover:underline'
                onClick={() => navigate('/specialist')}
              >
                {t('businessPage.buttons.business')}
              </Button>
              <div className='flex flex-col gap-4 sm:flex-row '>
                {(!isLoggedIn || (isLoggedIn && role === 'specialist')) && (
                  <Button
                    onClick={handleLogin}
                    variant='outline-botox'
                    className='h-12 min-w-[200px] max-w-[300px] flex-1 !py-2 !text-md2'
                  >
                    {t('hairdresserPage.buttons.book')}
                  </Button>
                )}
                {!isLoggedIn && <LoginButton />}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MainHeader;
