import React from 'react';

type TimeSlotProps = {
  timeSlot: Date;
  selected: boolean;
  handleSelectTime: (time: Date) => void;
};

const TimeSlot = ({ timeSlot, selected, handleSelectTime }: TimeSlotProps) => {
  return (
    <div
      onClick={() => {
        handleSelectTime(timeSlot);
      }}
      className={`inline-block cursor-pointer rounded-[28px] border border-accent px-4 py-2 ${
        selected ? 'bg-accent text-white' : ''
      }`}
    >
      {timeSlot.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}
    </div>
  );
};

export default TimeSlot;
