import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ModalInfo = {
  active: boolean;
  url: string | null;
};

export type ModalState = {
  error: ModalInfo;
  specialistCanceledOrder: ModalInfo;
  changesSaved: ModalInfo;
  cancelOrder: ModalInfo;
  cookiePolicy: ModalInfo;
  clientLeaveReview: ModalInfo;
  noSpecInLocation: ModalInfo;
  supportEmail: ModalInfo;
  rateSpecialist: ModalInfo;
  terms: ModalInfo;
  camera: ModalInfo;
  shareReferral: ModalInfo;
  register: ModalInfo;
  payment: ModalInfo;
  deletedAccount: ModalInfo;
};

type ToggleModalPayload = {
  modalType: keyof ModalState;
  url?: string;
};

const initialState: ModalState = {
  error: { active: false, url: null },
  specialistCanceledOrder: { active: false, url: null },
  changesSaved: { active: false, url: null },
  cancelOrder: { active: false, url: null },
  cookiePolicy: { active: true, url: null },
  clientLeaveReview: { active: false, url: null },
  noSpecInLocation: { active: false, url: null },
  supportEmail: { active: false, url: null },
  rateSpecialist: { active: false, url: null },
  terms: { active: false, url: null },
  camera: { active: false, url: null },
  shareReferral: { active: false, url: null },
  register: { active: false, url: null },
  payment: { active: false, url: null },
  deletedAccount: { active: false, url: null },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleModal: (state, { payload }: PayloadAction<ToggleModalPayload>) => {
      const modal = state[payload.modalType];
      modal.active = !modal.active;
      modal.url = payload.url || null;
    },
    closeModal: (state, { payload }: PayloadAction<keyof ModalState>) => {
      state[payload].active = false;
    },
  },
});

export const { toggleModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
