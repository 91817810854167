import { toast } from 'react-hot-toast';

type ToastType = 'success' | 'error' | 'loading';

const toastWithDismiss = (message: string, type: ToastType) => {
  const toastFunc = toast[type];
  toastFunc((t) => (
    <div onClick={() => toast.dismiss(t.id)} className='cursor-pointer'>
      {message}
    </div>
  ));
};

export default toastWithDismiss;
