import React from 'react';
import { FaCheck } from 'react-icons/fa6';

type StepItemTextProps = {
  title: string;
  description: string[];
  postDescription?: string[];
  step: number;
  list: string[];
};

const StepItemText = ({
  description,
  postDescription,
  title,
  step,
  list,
}: StepItemTextProps) => {
  return (
    <section className='font-inter text-[#2D1D1CCC]'>
      <h4 className='font-sans text-3xl font-medium sm:text-4xl'>
        <span className='italic text-accent'>{step}/</span> {title}
      </h4>
      {description.map((text, i) => (
        <p key={i} className='mb-2 text-md2'>
          {text}
        </p>
      ))}
      <ul className='flex flex-col gap-4 md:gap-2'>
        {list.map((item, i) => (
          <li key={i} className='flex items-start gap-2 text-md2'>
            <FaCheck
              color='#FFC700'
              size={16}
              className='w-full max-w-[16px]'
            />
            {item}
          </li>
        ))}
      </ul>
      {postDescription?.map((text, i) => (
        <p key={i} className='text-md2'>
          {text}
        </p>
      ))}
    </section>
  );
};

export default StepItemText;
