import React from 'react';
import { Order } from 'utils/types';
import { Link } from 'react-router-dom';
import Button from '../atoms/Button';
import OrderTimeRange from '../atoms/OrderTimeRange';

type TimeSpecificOrderButtonProps = {
  order: Order['orders'][number];
  url: string;
};

const TimeSpecificOrderButton = ({
  order,
  url,
}: TimeSpecificOrderButtonProps) => {
  return (
    <Link to={url}>
      <Button className='inline-flex w-full items-center justify-between !whitespace-normal text-left'>
        {order.serviceName}
        <OrderTimeRange timeOfOrder={order.timeOfOrder} dur={order.dur} />
      </Button>
    </Link>
  );
};

export default TimeSpecificOrderButton;
