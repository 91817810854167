import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useLocation } from 'react-router';
import i18n from '../../i18n';
import {
  Button,
  Dropdown,
  LanguageItem,
  toastWithDismiss,
  ToggleChevron,
} from 'ui';
import { BASE_URL } from 'api/api';
import { SupportedLanguages } from 'redux/userSlice';
import { getTypedStorageItem, setTypedStorageItem } from 'utils/storage';
import USIcon from 'images/flags/United States.svg';
import PLIcon from 'images/flags/Poland.svg';
import DEIcon from 'images/flags/Germany.svg';
import UAIcon from 'images/flags/Ukraine.svg';
import { useAppSelector } from 'hooks/useAppSelector';

type LanguageDropDownProps = {
  disableAbsolutePositioning?: boolean;
};

const needToReload = (pathname: string) => {
  return (
    pathname.startsWith('/category') ||
    pathname.startsWith('/services') ||
    pathname.startsWith('/specialist/services/select') ||
    pathname.includes('/cancel') ||
    pathname.includes('/privacy')
  );
};

const languageIcons = {
  ua: UAIcon,
  de: DEIcon,
  en: USIcon,
  pl: PLIcon,
};

const LanguageDropDown = ({
  disableAbsolutePositioning,
}: LanguageDropDownProps) => {
  const { pathname } = useLocation();
  const { isLoggedIn } = useAppSelector((state) => state.user);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    () => getTypedStorageItem('selectedLanguage') || 'en',
  );

  const dropdownRef = useOnclickOutside(() => {
    if (showLanguageDropdown) setShowLanguageDropdown(false);
  });

  useEffect(() => {
    setTypedStorageItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleSelectLanguage = async (
    language: (typeof SupportedLanguages)[number],
  ) => {
    try {
      setShowLanguageDropdown(false);
      setSelectedLanguage(language);
      if (isLoggedIn) {
        const response = await fetch(
          `${BASE_URL}app-config/changeLocale?selectedLocale=${language}`,
          {
            method: 'POST',
          },
        );
        if (response.ok && needToReload(pathname)) {
          window.location.reload();
        }
      }
    } catch (error: any) {
      toastWithDismiss('Error while changing language', 'error');
    }
  };

  return (
    <div ref={dropdownRef} className='relative inline-block font-sans'>
      <Button
        variant='text'
        className='relative flex items-center gap-2'
        onClick={() => setShowLanguageDropdown(!showLanguageDropdown)}
      >
        <img
          src={languageIcons[selectedLanguage]}
          alt={selectedLanguage}
          className='h-[16px] w-[23px] rounded-[15%] object-cover'
        />
        {selectedLanguage.toUpperCase()}
        <ToggleChevron size={15} isOpen={showLanguageDropdown} />
      </Button>

      <Dropdown
        show={showLanguageDropdown}
        disableAbsolutePositioning={disableAbsolutePositioning}
      >
        {SupportedLanguages.map((language) => (
          <LanguageItem
            handleSelectLanguage={handleSelectLanguage}
            language={language}
            key={language}
          />
        ))}
      </Dropdown>
    </div>
  );
};

export default LanguageDropDown;
