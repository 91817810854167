import React from 'react';
import { Button, ModalWrapper } from 'ui';
import { withModalHandling } from './withModalHandling';
import { IoClose } from 'react-icons/io5';
import LogoSad from 'images/icons/logo-sad.svg';

type LeaveReviewModalProps = {
  handleCloseModal?: () => void;
};

const LeaveReviewModal = ({ handleCloseModal }: LeaveReviewModalProps) => {
  return (
    <ModalWrapper modalId='deletedAccount'>
      <IoClose
        onClick={handleCloseModal}
        className='absolute right-4 top-4 cursor-pointer text-3xl'
      />
      <img src={LogoSad} width={37} height={37} alt='logo-sad' />
      <p className='text-md'>Your account has been deleted</p>
      <p className='text-secondary'>We hope to meet soon!</p>
      <Button
        variant='yellow'
        className='w-1/2 max-w-md'
        onClick={handleCloseModal}
      >
        OK
      </Button>
    </ModalWrapper>
  );
};

export default withModalHandling(LeaveReviewModal, 'deletedAccount');
