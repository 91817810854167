import React, { useState } from 'react';
import { IconType } from 'react-icons';

type InputProps = {
  type?: string;
  placeholder?: string;
  className?: string;
  helperText?: string;
  value?: string;
  accept?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;
  border?: boolean;
  inputMode?:
    | 'text'
    | 'search'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal';
  restrictSymbols?: boolean;
  min?: string | number;
  max?: string | number;
  Icon?: IconType | JSX.Element;
  onIconClick?: () => void;
  iconClassName?: string;
  label?: string;
  wrapperClassName?: string;
  iconTitle?: string;
  readOnly?: boolean;
  variant?: 'default' | 'outline';
  onBlur?: () => void;
  onFocus?: () => void;
};

const Input = React.forwardRef<any, InputProps>(
  (
    {
      helperText,
      value,
      accept,
      onChange,
      hasError,
      required,
      disabled,
      inputMode,
      restrictSymbols,
      min,
      max,
      Icon,
      onIconClick,
      iconClassName,
      label,
      type = 'text',
      placeholder = '',
      className = '',
      wrapperClassName = '',
      border = true,
      iconTitle,
      readOnly,
      variant = 'default',
      onBlur,
      onFocus,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
      setIsFocused(true);
      onFocus && onFocus();
    };

    const handleBlur = () => {
      setIsFocused(false);
      onBlur && onBlur();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value.replace(/<[^>]*>?/gm, '');

      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: newValue,
          },
        });
      }
    };

    return (
      <div className={`input-with-placeholder relative ${wrapperClassName}`}>
        {label && (
          <label
            className={`pointer-events-none block select-none text-sm text-secondary ${
              isFocused || value ? 'label-top' : ''
            }`}
          >
            {label}
          </label>
        )}
        <input
          ref={ref}
          type={type}
          placeholder={placeholder}
          accept={accept}
          value={value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`${className} ${
            hasError
              ? 'border-error hover:border-error focus:border-error'
              : 'border-secondary'
          } my-1 w-full outline-none transition-all hover:border-accent focus:border-accent disabled:bg-white ${
            required ? 'required-input' : ''
          } ${
            variant === 'outline'
              ? 'rounded-lg border px-2 py-4'
              : 'rounded-none border-b-[1.5px]'
          }`}
          {...props}
        />
        {Icon && typeof Icon === 'function' && (
          <Icon
            onClick={onIconClick}
            title={iconTitle}
            size={20}
            className={`absolute right-2 top-1/2 -translate-y-1/2 transform ${iconClassName}`}
          />
        )}
        {Icon && typeof Icon !== 'function' && (
          <span
            className={`absolute right-2 top-1/2 inline-flex -translate-y-1/2 items-center justify-center gap-2 ${iconClassName}`}
          >
            {Icon}
          </span>
        )}
        {helperText && (
          <p
            className={`${
              hasError ? 'text-error' : ''
            } -mt-2 mb-2 break-words text-sm`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  },
);

export default Input;
