import React from 'react';
import { Service } from 'utils/types';
import { FaCirclePlus } from 'react-icons/fa6';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { Button } from 'ui';
import { convertMsToMin } from 'utils/time';
import { formatCurrency } from 'utils/validations';

type ServiceSelectionButtonProps = {
  service: Service;
  isChosen: boolean;
  onClick: () => void;
};

const ServiceSelectionButton = ({
  isChosen,
  service,
  onClick,
}: ServiceSelectionButtonProps) => {
  const formattedPrice = formatCurrency(
    service.gssDto.currency,
    service.gssDto.price,
  );

  return (
    <li>
      <Button
        onClick={onClick}
        variant='grayStroke'
        className={`flex w-full items-center justify-between ${
          isChosen ? '!border-accent-80' : ''
        }`}
      >
        <div className='flex flex-col items-start'>
          <p
            title={service.name}
            className='scrollbar-button overflow-x-auto text-sm text-gray-1 sm:max-w-[250px] smMax:max-w-[65vw] xs:max-w-[30vw] max-500:max-w-[45vw]'
          >
            {service.name}
          </p>
          <p className='text-left text-sm text-secondary'>
            {convertMsToMin(service.gssDto.duration)} min
          </p>
        </div>
        <div className='flex items-center gap-3'>
          <p className='text-sm text-gray-2'>{formattedPrice}</p>
          {isChosen ? (
            <RiCheckboxCircleFill className='text-accent' size={24} />
          ) : (
            <FaCirclePlus className='text-accent' size={20} />
          )}
        </div>
      </Button>
    </li>
  );
};

export default ServiceSelectionButton;
