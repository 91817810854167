import React from 'react';

type CardWithIconProps = {
  title: string;
  description: string;
  icon: string;
};

const CardWithIcon = ({ title, icon, description }: CardWithIconProps) => {
  return (
    <div className='mx-auto flex max-w-[365px] flex-col gap-2 rounded-[20px] border-2 border-accent p-6'>
      <div className='flex h-16 w-16 items-center justify-center rounded-lg bg-[#FDDC0236] p-2'>
        <img src={icon} alt={'icon ' + title} width={32} height={32} />
      </div>
      <div>
        <h3 className='mb-1 text-lg font-medium text-gray-6'>{title}</h3>
        <p className='text-gray-5'>{description}</p>
      </div>
    </div>
  );
};

export default CardWithIcon;
