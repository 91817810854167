import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import SettingsList, { Settings } from 'components/Settings/SettingsList';
import { UserAvatar, Wrapper, SpecialistStats, SectionTitle } from 'ui';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { BiSolidPencil } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import AcceptToast from 'modals/AcceptToast';
import { logOut } from 'api/login';
import toast from 'react-hot-toast';
import SocialSection from 'components/Profile/SocialSection';

const getSettingsProfile = (
  uid: string,
  t: TFunction<'translation', undefined>,
): Settings[] => [
  { name: t('profile.services'), link: '/specialist/services' },
  { name: t('profile.create_order'), link: '/specialist/create-order' },
  { name: t('menu.calendar'), link: '/specialist/calendar' },
  {
    name: t('profile.portfolio'),
    link: `/specialist/portfolio/${uid}`,
  },
  { name: t('profile.schedule'), link: '/specialist/schedule' },
  // { name: t('profile.balance'), link: '/specialist/balance' },
  { name: t('edit.profile_location'), link: '/location' },
  { name: t('menu.support'), link: '/support' },
];

const ProfilePage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const {
    firstName,
    since,
    reviewNumber,
    rank,
    imagesAmount,
    profilePhoto,
    uid,
    role,
  } = useAppSelector((state) => state.user);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('ref');
  const navigate = useNavigate();

  useEffect(() => {
    if (referralCode) {
      AcceptToast({
        saveButtonText: t('menu.logout'),
        cancelButtonText: t('client.remove_favorite.no'),
        text: t('referral.specialits'),
        handleSaveClick: async () => {
          await logOut(
            uid,
            role as 'client' | 'specialist',
            dispatch,
            navigate,
          );
          navigate('/login?ref=' + referralCode);
          toast.dismiss();
        },
        duration: 100000,
      });
    }
  }, [referralCode, uid, role, dispatch, navigate, t]);

  return (
    <Wrapper className='flex flex-col gap-4'>
      <div className='flex items-center justify-between gap-3 smMax:mt-2 xs:flex-col xs:gap-3'>
        <div className='relative flex flex-col items-center justify-center gap-3'>
          <UserAvatar src={profilePhoto} size={120} showLikeButton={false} />
          <BiSolidPencil
            title={t('edit_profile.header')}
            size={40}
            onClick={() => navigate('/specialist/profile/edit')}
            className='absolute -bottom-2 -right-3 cursor-pointer rounded-full bg-white p-[10px] text-secondary shadow-lg transition-all hover:text-primary active:scale-95'
          />
        </div>
        <SpecialistStats
          reviewsNumber={reviewNumber}
          rank={rank}
          imagesAmount={imagesAmount}
          portfolioUid={uid}
          reviewsUid={uid}
        />
      </div>
      <SectionTitle
        title={firstName}
        subtitle={`${t('edit_profile.since')} ${since}`}
      />
      <SocialSection />
      <SettingsList
        title={t('profile.settings')}
        settings={getSettingsProfile(uid, t)}
      />
    </Wrapper>
  );
};

export default ProfilePage;
