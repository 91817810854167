import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logOutUser } from './actions';

export type OrderTimeState = {
  dateStamp: number;
  timeStamp: number;
  formattedDate: string;
  timeSlotId: number | null;
  timeSlots: {
    id: number;
    startTime: string;
    status: string;
    gserviceSpecialistId: string;
  }[];
};

const initialState: OrderTimeState = {
  dateStamp: new Date().getTime(),
  timeStamp: new Date().getTime(),
  formattedDate: '',
  timeSlotId: null,
  timeSlots: [],
};

const orderTimeSlice = createSlice({
  name: 'orderTime',
  initialState,
  reducers: {
    clearOrderTime: () => initialState,
    setOrderTime: (
      state,
      {
        payload,
      }: PayloadAction<{
        dateStamp: number;
        timeStamp: number | null;
        timeSlotId: number | null;
      }>,
    ) => {
      state.dateStamp = payload.dateStamp;

      const date = new Date(payload.dateStamp);
      const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };

      const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
      };

      const formattedDate = date.toLocaleDateString('de', dateOptions);
      const formattedTime = payload.timeStamp
        ? date.toLocaleTimeString('de', timeOptions)
        : '';

      state.formattedDate = formattedTime
        ? `${formattedDate} ${formattedTime}`
        : formattedDate;

      state.timeSlotId = payload.timeSlotId;
    },
    setTimeSlotId: (state, { payload }: PayloadAction<number>) => {
      state.timeSlotId = payload;
    },
    setTimeSlots: (
      state,
      { payload }: PayloadAction<OrderTimeState['timeSlots']>,
    ) => {
      state.timeSlots = payload.filter(
        (slot) =>
          new Date(slot.startTime).getTime() >=
          new Date().getTime() + 30 * 60 * 1000,
      );
    },
  },

  extraReducers(builder) {
    builder.addCase(logOutUser, () => initialState);
  },
});

export const { setOrderTime, clearOrderTime, setTimeSlots, setTimeSlotId } =
  orderTimeSlice.actions;
export default orderTimeSlice.reducer;
