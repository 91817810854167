import React, { useState } from 'react';
import { toggleModal } from 'redux/modalSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Button, SectionHeader, Wrapper } from 'ui';
import { BASE_URL, cancelOrderByRole } from 'api/api';
import { useLocation } from 'react-router';
import { useApiRequest } from 'hooks/useApiRequest';
import PersonCancelIcon from 'images/emoji/(cansel order).svg';
import OrderNotCompleteIcon from 'images/emoji/Order was not completed .svg';
import { useAppSelector } from 'hooks/useAppSelector';
import { motion } from 'framer-motion';
import { buttonsAppearVariants } from '../customer/ServicesPage';
import { CancelReason } from 'utils/types';
import { useTranslation } from 'react-i18next';

const CancelOrderPage = () => {
  const { t } = useTranslation();

  const [cancelReason, setCancelReason] = useState('');
  const dispatch = useAppDispatch();
  const { state: orderId } = useLocation();
  const { role } = useAppSelector((state) => state.user);
  const { data: cancelReasons, loading: loadingCancelReasons } = useApiRequest<
    CancelReason[]
  >({
    endpoint: `${BASE_URL}orders/${role}/cancelReasons`,
    destructuringDataType: 'cancelReasons',
  });

  const handleCancelOrder = async () => {
    const result = await cancelOrderByRole(orderId, cancelReason, role);
    if (result) {
      dispatch(toggleModal({ modalType: 'cancelOrder' }));
    } else dispatch(toggleModal({ modalType: 'error' }));
  };

  const showIcon = role === 'client' ? PersonCancelIcon : OrderNotCompleteIcon;

  return (
    <Wrapper flexColumnSpaceBetween>
      <div className='mb-2 flex flex-col gap-9'>
        <SectionHeader
          title={t('cancel_order.name')}
          subtitle={t('cancel_order.description')}
        />
        <ul className='flex w-full flex-col gap-3'>
          {!loadingCancelReasons &&
            Array.isArray(cancelReasons) &&
            Array.isArray(cancelReasons) &&
            cancelReasons.map((reasonObj, index: number) => (
              <motion.li
                key={reasonObj.displayName}
                variants={buttonsAppearVariants}
                initial='hidden'
                animate='visible'
                transition={{ delay: index * 0.04 }}
              >
                <Button
                  onClick={() => setCancelReason(reasonObj.reason)}
                  className={`flex h-[60px] w-full items-center py-3 text-left ${
                    cancelReason === reasonObj.reason && '!border-accent-80'
                  }`}
                  variant='grayStroke'
                >
                  <img
                    src={showIcon}
                    alt={`cancel order icon`}
                    width={14}
                    height={14}
                    className='mr-4'
                  />
                  {reasonObj.displayName}
                </Button>
              </motion.li>
            ))}
        </ul>
      </div>
      <Button
        variant='yellow'
        disabled={!cancelReason}
        onClick={handleCancelOrder}
        size='lg'
      >
        {t('cancel_order.button')}
      </Button>
    </Wrapper>
  );
};

export default CancelOrderPage;
