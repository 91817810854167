import { FaCirclePlus, FaCircleMinus } from 'react-icons/fa6';
import { Input } from 'ui';

interface CounterInputProps {
  counter: {
    value: number;
    increment: () => void;
    decrement: () => void;
  };
  label: string;
  onChange: (value: string) => void;
  icon?: string;
}

const CounterInput = ({
  counter,
  label,
  onChange,
  icon,
}: CounterInputProps) => (
  <div className='flex items-center justify-between border-b border-gray-10 pb-3'>
    <div className='flex gap-2'>
      <img src={icon} alt='icon' width={24} height={24} className='xs:hidden' />
      <p>{label}</p>
    </div>
    <div className='flex min-w-[109px] justify-between gap-5'>
      <FaCircleMinus
        className='cursor-pointer select-none transition-all active:scale-95'
        color='#FFC700'
        size={25}
        onClick={counter.decrement}
      />
      <Input
        type='number'
        value={counter.value?.toString() || ''}
        onChange={(e) => onChange(e.target.value)}
        className='max-w-[50px] border-b-0 text-center'
      />
      <FaCirclePlus
        className='cursor-pointer select-none transition-all active:scale-95'
        color='#FFC700'
        size={25}
        onClick={counter.increment}
      />
    </div>
  </div>
);

export default CounterInput;
