import React, { useState } from 'react';
import { PiPlusThin } from 'react-icons/pi';
import { motion } from 'framer-motion';

type FAQItemProps = {
  question: string;
  answer: string;
};

const FAQItem = ({ question, answer }: FAQItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li
      className={`flex cursor-pointer flex-col rounded-[20px] px-8 py-[15px] outline outline-1 ${
        !isOpen ? 'outline-[#E5E1E1]' : 'outline-accent'
      }`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className='flex flex-row items-center justify-between'>
        <h4 className='font-sans text-md2 md:text-2xl'>{question}</h4>
        <div
          className={`rounded-[5px] border md:rounded-[10px] ${
            isOpen ? 'border-accent' : ''
          }`}
        >
          <PiPlusThin
            className={`h-[23px] w-[23px] transition-all md:h-[46px] md:w-[46px] ${
              isOpen ? 'rotate-45 ' : ''
            }`}
          />
        </div>
      </div>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.2 }}
        className='overflow-hidden'
      >
        <p className='font-inter leading-6 text-[#3C3E38B2] md:text-md2'>
          {answer}
        </p>
      </motion.div>
    </li>
  );
};

export default FAQItem;
