import React from 'react';
import Quotes from 'images/main-specialist/quotes.png';

type ReviewItemProps = {
  avatar: string;
  name: string;
  review: string;
  occupation: string;
};

const ReviewItem = ({ avatar, review, occupation, name }: ReviewItemProps) => {
  return (
    <div className='relative h-full w-[320px] rounded-[20px] border border-[#ECC4A7] px-8 pb-32 pt-16 xs:w-[80vw]'>
      <div className='flex justify-center'>
        <img
          src={avatar}
          className='absolute -top-16 h-[97px] w-[97px] rounded-full object-cover'
          alt='avatar'
          loading='lazy'
        />
      </div>
      <div className='max-w-[280px]'>
        <p className='text-md2'>{review}</p>
        <p className='mt-10 text-md2 text-secondary'>{occupation}</p>
        <p className='text-md2 text-[#5E5B5B]'>{name}</p>
      </div>
      <img
        src={Quotes}
        className='absolute -bottom-4 right-11'
        alt=''
        width={54}
        height={54}
      />
    </div>
  );
};

export default ReviewItem;
