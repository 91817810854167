import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import { Button } from 'ui';

type VerificationFormProps = {
  isLoading: boolean;
  isValid: boolean;
  handleLoginUser: (
    smsCode: string,
    e?: React.FormEvent<HTMLFormElement>,
  ) => void;
  handleResendCode: () => void;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const VerificationForm = ({
  isLoading,
  isValid,
  handleLoginUser,
  setIsValid,
  handleResendCode,
}: VerificationFormProps) => {
  const { t } = useTranslation();
  const [isSentTwice, setisSentTwice] = useState(false);
  const [code, setCode] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeInput = (value: string) => {
    setCode(value);
    if (value.length === 6) {
      handleLoginUser(value);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  useEffect(() => {
    setIsValid(true);
  }, [code, setIsValid]);

  return (
    <>
      <form
        onSubmit={(e) => handleLoginUser(code, e)}
        className='flex flex-col gap-10'
        id='verification-form'
      >
        <div className='flex max-w-[95vw] justify-center '>
          <VerificationInput
            ref={inputRef}
            validChars='0-9'
            inputProps={{
              inputMode: 'numeric',
              autoComplete: 'one-time-code',
              style: {
                WebkitBoxShadow: '0 0 0px 0px white inset',
                boxShadow: '0 0 0px 0px white inset',
                WebkitTextFillColor: 'transparent',
              },
            }}
            placeholder=''
            length={6}
            classNames={{
              container: 'border-0 ',
              character: `border-0 border-b-2 text-center ${
                isValid ? '' : 'border-error'
              } `,
              characterInactive: 'outline-none bg-white text-primary',
              characterSelected: 'outline-none border-accent text-primary',
            }}
            value={code}
            onChange={handleChangeInput}
            autoFocus={true}
          />
        </div>
        {!isValid && (
          <p className='text-center text-error'>{t('log_in.invalid_sms')}</p>
        )}
        <div className='space-y-5'>
          {!isSentTwice && (
            <p
              className='cursor-pointer text-center text-accent underline'
              onClick={() => {
                setCode('');
                setisSentTwice(true);
                handleResendCode();
              }}
            >
              {t('log_in.sms_2')}
            </p>
          )}
        </div>
      </form>

      <Button
        className='mt-6 w-full py-4'
        variant='yellow'
        type='submit'
        loading={isLoading}
      >
        {t('log_in.continue')}
      </Button>
    </>
  );
};

export default VerificationForm;
