import { CountryCode } from './types';

interface Schema {
  gotouAccessToken: string;
  gotouRefreshToken: string;
  gotouTokenType: string;
  selectedLanguage: 'en' | 'de' | 'ua' | 'pl';
  country: CountryCode;
}

export const setTypedStorageItem = <T extends keyof Schema>(
  key: T,
  value: Schema[T],
): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getTypedStorageItem = <T extends keyof Schema>(
  key: T,
): Schema[T] | null => {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item) as Schema[T];
  } catch (e) {
    return item as unknown as Schema[T];
  }
};

export const removeTypedStorageItem = <T extends keyof Schema>(
  key: T,
): void => {
  localStorage.removeItem(key);
};
