import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePlacesAutocomplete, {
  LatLng,
  getDetails,
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import toast from 'react-hot-toast';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { BsGeoAltFill } from 'react-icons/bs';

import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getBrowserLocation } from 'utils/geo';
import { setUserLocation } from 'redux/userSlice';
import { fetchRequestBoolean, updateClientAddress } from 'api/api';

import Map from 'components/Location/Map';
import Autocomplete from 'components/Location/Autocomplete';
import { Button, GoBackButton, Sheet, Wrapper } from 'ui';

const LocationPage = () => {
  const { t } = useTranslation();
  const [LIBRARIES] = useState<Libraries>(['places']);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationSelected, setLocationSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const {
    isLoggedIn,
    role,
    uid,
    address: userAddress,
    coordinates: userCoordinates,
  } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedCoordinates, setSelectedCoordinates] =
    useState(userCoordinates);
  const [selectedCenter, setSelectedCenter] = useState(userCoordinates);

  const linkPath = isLoggedIn
    ? role === 'client'
      ? '/category'
      : '/specialist/profile'
    : '/login';

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: LIBRARIES,
  });

  const {
    ready,
    value,
    init,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 300,
  });

  useEffect(() => {
    if (userAddress && userCoordinates) {
      setValue(userAddress);
      setSelectedCoordinates(userCoordinates);
      setLocationSelected(true);
    }
  }, [userAddress, userCoordinates, setValue]);

  useEffect(() => {
    if (
      userCoordinates.lat === 0 &&
      userCoordinates.lng === 0 &&
      userAddress &&
      isLoaded
    ) {
      getGeocode({ address: userAddress })
        .then((results) => getLatLng(results[0]))
        .then((latLng) => setSelectedCoordinates(latLng))
        .catch((error) => console.log('Error: ', error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    const fetchLocation = async () => {
      const geocode = await getGeocode({ location: selectedCoordinates });
      const address = geocode[0].formatted_address;
      setValue(address, false);
      setLocationSelected(true);
    };

    if (
      selectedCoordinates.lat !== 0 &&
      selectedCoordinates.lng !== 0 &&
      isLoaded
    ) {
      fetchLocation();
    }
  }, [selectedCoordinates, setValue, isLoaded]);

  const handleSelect =
    ({ description }: any) =>
    () => {
      // When the user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setHasError(false);
      setValue(description, false);
      setLocationSelected(true);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const LatLng = getLatLng(results[0]);
        setSelectedCoordinates(LatLng);
        setSelectedCenter(LatLng);
      });
    };

  const handleCoordinatesMove = useCallback((LatLng: LatLng) => {
    setSelectedCoordinates(LatLng);
  }, []);

  const handleSaveLocation = async () => {
    if (!value || !isLocationSelected) {
      setHasError(true);
      return;
    }
    setHasError(false);
    setIsLoading(true);

    // Get geocode by address
    let geocode = await getGeocode({ address: value });
    let placeId = geocode[0].place_id; // Get place_id

    // Get details by place_id
    let details = await getDetails({
      placeId: placeId,
      language: 'en',
    });
    let city = '';
    let country = '';

    // Extract city and country
    if (typeof details === 'string' || !details) return;
    if (details.address_components) {
      details.address_components.forEach((component) => {
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      });
    }

    // Check if city or country is not found
    if (!city || !country) {
      toast.error(t('select_city_country'));
      setIsLoading(false);
      return;
    }

    if (role === 'client') {
      await updateClientAddress({
        name: value,
        city,
        country,
        lat: selectedCoordinates.lat,
        lon: selectedCoordinates.lng,
        placeId,
      });
      setIsLoading(false);
    }
    if (role === 'specialist') {
      await fetchRequestBoolean(`specialists/${uid}/location`, 'POST', {
        fullName: value,
        city,
        country,
        longitude: selectedCoordinates.lng,
        latitude: selectedCoordinates.lat,
        placeId,
      });
      setIsLoading(false);
    }

    dispatch(
      setUserLocation({
        address: value,
        coordinates: selectedCoordinates,
        city,
        country,
      }),
    );
    navigate(linkPath);
  };

  return (
    <Wrapper className='!p-0'>
      <GoBackButton className='absolute left-5 top-5 z-20' />
      <div className='relative'>
        <Map
          center={selectedCoordinates}
          selectedCenter={selectedCenter}
          isLoaded={isLoaded}
          className='!h-[60dvh] sm:!h-[78dvh]'
          onCenterChanged={handleCoordinatesMove}
          isDraggable
          canZoom
          controlZoomVisible={false}
        />
        <BsGeoAltFill
          className='absolute bottom-8 right-5 z-10 cursor-pointer rounded-[50%] bg-white p-3 text-accent-80 transition-all active:p-[14px]'
          size={50}
          onClick={() =>
            getBrowserLocation(
              setSelectedCoordinates,
              setValue,
              clearSuggestions,
              setLocationSelected,
            )
          }
        />
      </div>
      <Sheet
        className='z-20 justify-between sm:min-h-[200px] smMax:min-h-[32vh]'
        canDrag
        dragConstraints={{ bottom: 10 }}
      >
        <div className='flex flex-col gap-4'>
          <h3 className='min-h-[28px] text-center text-lg font-medium'>
            {value ? value : t('location.enter_location')}
          </h3>
          {hasError && (
            <p className='text-center text-error'>
              Please{' '}
              {!isLocationSelected ? 'select an option' : 'enter an address'}
            </p>
          )}
          <Autocomplete
            isLoaded={isLoaded}
            ready={ready}
            value={value}
            setValue={setValue}
            status={status}
            init={init}
            data={data}
            clearSuggestions={clearSuggestions}
            setIsOptionSelected={setLocationSelected}
            handleSelect={handleSelect}
          />
        </div>
        <Button
          variant='yellow'
          className='mb-1 w-full py-4'
          onClick={handleSaveLocation}
          loading={isLoading}
        >
          {t('edit_address.start_gotou')}
        </Button>
      </Sheet>
    </Wrapper>
  );
};

export default LocationPage;
