import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import FAQItem from './FAQItem';

const fawqs = [
  {
    question: 'What is Botox?',
    answer:
      'Botox is a drug made from a toxin produced by the bacterium Clostridium botulinum. It’s the same toxin that causes a life-threatening type of food poisoning called botulism.',
  },
  {
    question: 'How does Botox work?',
    answer:
      'Botox blocks signals from the nerves to the muscles. The injected muscle can no longer contract, which causes the wrinkles to relax and soften.',
  },
  {
    question: 'What is Botox used for?',
    answer:
      'Botox is used medically to treat certain muscular conditions and cosmetically to remove wrinkles by temporarily paralyzing muscles.',
  },
];

const FAQSection = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isBotoxPage = pathname.includes('botox');

  const showFAQ = isBotoxPage
    ? fawqs
    : (t('hairdresserPage.faq.faqs', {
        returnObjects: true,
      }) as { question: string; answer: string }[]);

  return (
    <section className='main-page-wrapper max-w-[1440px]' id='faq'>
      <h3 className='mx-auto mb-[22px] max-w-[450px] text-center text-[2.81rem] leading-[4.219rem]'>
        {t('hairdresserPage.faq.heading')}
      </h3>
      <ul className='flex flex-col gap-7'>
        {showFAQ.map((faq, i) => (
          <FAQItem key={i} {...faq} />
        ))}
      </ul>
    </section>
  );
};

export default FAQSection;
