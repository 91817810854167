import React from 'react';
import StepItemText from './StepItemText';
import FirstStepImg from 'images/main-specialist/Step1.webp';
import SecondStepImg from 'images/main-specialist/Step2.webp';
import ThirdStepImg from 'images/main-specialist/Step3.webp';
import FourthStepImg from 'images/main-specialist/Step4.webp';
import SectionTitle from './SectionTitle';
import { guidesSpecialist } from 'utils/mock';
import { useTranslation } from 'react-i18next';

const GuideSection = () => {
  const { t } = useTranslation();
  const guides = guidesSpecialist(t);

  return (
    <section className='main-page-wrapper flex max-w-[1440px] flex-col items-center py-12'>
      <SectionTitle
        title={t('businessPage.guide.title')}
        description={t('businessPage.guide.description')}
      />
      <div className='mt-16 flex flex-col gap-16'>
        <div className='flex flex-col items-center gap-8 md:flex-row-reverse'>
          <StepItemText {...guides[0]} />
          <img
            src={FirstStepImg}
            alt=''
            className='my-4 w-full max-w-[500px] object-contain sm:w-[80%] md:max-w-[40%]'
            loading='lazy'
          />
        </div>
        <div className='flex flex-col items-center gap-8 md:flex-row'>
          <StepItemText {...guides[1]} />
          <img
            src={SecondStepImg}
            alt=''
            className='my-4 w-full max-w-[500px] object-contain sm:w-[80%] md:max-w-[40%]'
            loading='lazy'
          />
        </div>
        <div className='flex flex-col items-center gap-8 md:flex-row-reverse'>
          <StepItemText {...guides[2]} />
          <img
            src={ThirdStepImg}
            alt=''
            className='my-4 w-full max-w-[500px] object-contain sm:w-[80%] md:max-w-[40%]'
          />
        </div>
        <div className='flex flex-col items-center gap-8 md:flex-row'>
          <StepItemText {...guides[3]} />
          <img
            src={FourthStepImg}
            alt=''
            loading='lazy'
            className='my-4 w-full max-w-[500px] object-contain sm:w-[80%] md:max-w-[40%]'
          />
        </div>
      </div>
    </section>
  );
};

export default GuideSection;
