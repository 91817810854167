import React from 'react';

type WrapperProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  noHorizontalPadding?: boolean;
  flexColumnSpaceBetween?: boolean;
};

const Wrapper = ({
  children,
  className,
  style,
  noHorizontalPadding,
  flexColumnSpaceBetween,
}: WrapperProps) => {
  return (
    <main
      className={`relative min-h-[92dvh] min-w-[100vw] overflow-hidden bg-white pb-5 sm:min-h-[750px] sm:min-w-pcScreen sm:max-w-[500px] sm:rounded-2xl sm:pt-5 ${
        noHorizontalPadding ? 'px-0' : 'px-5'
      } ${className} ${
        flexColumnSpaceBetween ? 'flex flex-col justify-between' : ''
      }`}
      style={style}
    >
      {children}
    </main>
  );
};

export default Wrapper;
