import React from 'react';
import SpecialistAvatar from './UserAvatar';
import { Review } from 'utils/types';
import { convertMsToTime } from 'utils/time';
import { FaStar, FaStarHalfStroke } from 'react-icons/fa6';
import { getTypedStorageItem } from 'utils/storage';

type UserReviewProps = {
  review: Review;
};

const UserReview = ({ review }: UserReviewProps) => {
  const { ts, surname, review: reviewMsg, name, rate, imgURL } = review;

  const selectedLanguage = getTypedStorageItem('selectedLanguage') || 'en-US';
  const formattedDate = new Intl.DateTimeFormat(selectedLanguage, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(ts));

  return (
    <li className='flex flex-col gap-6 pt-2'>
      <div className='flex gap-2'>
        {[...Array(5)].map((_, i) => {
          const ratingValue = i + 1;
          return (
            <span key={i}>
              {ratingValue <= Math.floor(rate) ? (
                <FaStar size={15} color='#ffc107' />
              ) : ratingValue - 0.5 === rate ? (
                <FaStarHalfStroke size={15} color='#ffc107' />
              ) : (
                <FaStar size={15} color='#e4e5e9' />
              )}
            </span>
          );
        })}
      </div>
      <div className='flex gap-3'>
        <SpecialistAvatar
          size={48}
          src={imgURL}
          showLikeButton={false}
          circleAvatar
        />
        <div className='flex flex-col '>
          <p>
            {name} {surname}
          </p>
          <p className='text-secondary'>
            {formattedDate} {convertMsToTime(ts)}
          </p>
        </div>
      </div>
      <p>{reviewMsg}</p>
    </li>
  );
};

export default UserReview;
