import React from 'react';
import { motion } from 'framer-motion';

type FeatureCardProps = {
  order: number;
  title: string;
  description: string;
};

const FeatureCard = ({ order, title, description }: FeatureCardProps) => {
  return (
    <motion.div
      className='rounded-[20px] border border-main.border px-[22px] py-[23px]'
      initial={{ opacity: 0, y: 120 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, ease: 'easeOut' }}
    >
      <p className='ml-auto font-sans text-[17.58px] leading-[26.37px] text-accent'>
        0{order}
      </p>
      <h4 className='text-center font-sans text-[2rem] italic leading-[3rem]'>
        {title}
      </h4>
      <p className='text-center font-inter text-md2 text-[#3C3E38B2]'>
        {description}
      </p>
    </motion.div>
  );
};

export default FeatureCard;
