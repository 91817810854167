import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { BASE_URL, acceptSpecialist } from 'api/api';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  Button,
  CircleLogo,
  Wrapper,
  Sheet,
  ExpandedSpecialistStats,
  GoBackButton,
} from 'ui';
import { LocationDto, OrderFound } from 'utils/types';
import { formatCurrency } from 'utils/validations';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  setCanFetchNewOrder,
  setSearchOrderData,
} from 'redux/searchOrderSlice';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import useGeocodeTimeZone from 'hooks/useGeocodeTimeZonet';
import { setCoordinates } from 'redux/mapSlice';
import { getTypedStorageItem } from 'utils/storage';
import { toggleModal } from 'redux/modalSlice';

type Body = {
  address: LocationDto;
  serviceId: string;
  clientId?: string;
  fromTs: number;
  timeSlotId: number | null;
  orderSource: 'byClient' | 'bySpecialist';
};

const beauticianSubcategories = [
  'beautician',
  'kosmetyczka',
  'kosmetikerin',
  'косметолог',
];

const isBeauticianSubcategory = (subcategoryName: string) => {
  return beauticianSubcategories.includes(subcategoryName.toLowerCase());
};

const SearchSpecialistsPage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { serviceId } = useAppSelector((state) => state.service);
  const {
    timeOfOrder,
    specialistIdStr,
    since,
    serviceName,
    reviewsNumber,
    rank,
    price,
    photoURL,
    orderIdStr,
    name,
    imagesAmount,
    found,
    currency,
    address,
    canFetchNewOrder,
    duration,
    latitude,
    longitude,
  } = useAppSelector((state) => state.searchOrder);
  const {
    uid,
    city,
    country,
    coordinates,
    isLoggedIn,
    address: userAddress,
  } = useAppSelector((state) => state.user);
  const { dateStamp, timeSlotId } = useAppSelector((state) => state.orderTime);
  const { selectedSubcategoryName } = useAppSelector((state) => state.category);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#FFD84E';

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const body: Body = {
    address: {
      city,
      country,
      fullName: userAddress,
      longitude: coordinates?.lng,
      latitude: coordinates?.lat,
    },
    serviceId: serviceId,
    clientId: uid,
    orderSource: 'byClient',
    fromTs: dateStamp,
    timeSlotId,
  };

  const { data, loading } = useApiRequest<OrderFound>({
    conditionToFetch: canFetchNewOrder && !!timeSlotId,
    endpoint: `${BASE_URL}specialist/createOrderAndFindSpecialist`,
    requestMethod: 'POST',
    requestBody: body,
  });

  const timeZoneId = useGeocodeTimeZone({
    location: { lat: latitude, lng: longitude },
  });

  const selectedLanguage = getTypedStorageItem('selectedLanguage') || 'en-US';
  const formattedStartTime = new Date(timeOfOrder).toLocaleString(
    selectedLanguage,
    {
      timeZone: timeZoneId,
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  );
  const formattedEndTime = new Date(timeOfOrder + duration).toLocaleString(
    selectedLanguage,
    {
      timeZone: timeZoneId,
      hour: 'numeric',
      minute: 'numeric',
    },
  );

  const formattedPrice = formatCurrency(currency, price);

  const handleAcceptSpecialist = async () => {
    if (!isLoggedIn) {
      dispatch(toggleModal({ modalType: 'register' }));
      return;
    }
    if (!timeSlotId) {
      toast.error(t('Please, select a time slot'));
      return;
    }

    setIsLoading(true);
    const response = await acceptSpecialist(
      orderIdStr,
      specialistIdStr,
      timeOfOrder,
      timeSlotId,
    );
    setIsLoading(false);
    if (response) {
      const url = isBeauticianSubcategory(selectedSubcategoryName)
        ? `/orders/${orderIdStr}/photo`
        : `/orders/${orderIdStr}`;
      navigate(url, { state: { from: '/search' } });
      dispatch(setCanFetchNewOrder(true));
    } else {
      toast.error(t('error_accepting'));
    }
  };

  useEffect(() => {
    if (data?.name) {
      if (found) {
        dispatch(setCanFetchNewOrder(false));
        dispatch(setCoordinates({ lat: data.latitude, lng: data.longitude }));
      }
      dispatch(setSearchOrderData(data));
    }
  }, [data, dispatch, found]);

  return (
    <Wrapper
      className={`flex flex-col content-center items-center !bg-[#FFD84E]`}
    >
      <GoBackButton className='absolute left-5 top-5 z-10 border-[#646464]' />
      <CircleLogo avatar={photoURL} loading={loading} />
      {loading && (
        <div>
          <h4 className='mb-4 mt-24 text-center text-xl font-medium text-white'>
            {t('finding_specialist')}
          </h4>
        </div>
      )}
      {!loading && (
        <Sheet
          showShadow={false}
          y={100}
          dragConstraints={{ bottom: 200, top: 10 }}
          className='z-20'
        >
          {found ? (
            <>
              <h3 className='mt-2 text-2xl font-medium text-primary'>
                {name} <span>{serviceName}</span>
                <br />
                <span>
                  {formattedStartTime} - {formattedEndTime}
                </span>
              </h3>
              <p className='text-secondary'>
                {name} {t('order.specialist_found.since')} {since}
              </p>
              <ExpandedSpecialistStats
                rank={rank}
                reviewsNumber={reviewsNumber}
                imagesAmount={imagesAmount}
                price={formattedPrice}
                portfolioUid={specialistIdStr}
                reviewsUid={specialistIdStr}
                location={address}
              />
              <div className='mt-4 flex w-full flex-col gap-4'>
                <Button
                  variant='yellow'
                  onClick={handleAcceptSpecialist}
                  loading={loading || isLoading}
                  className='py-4'
                >
                  {t('ok')}
                </Button>

                <Button
                  variant='yellowStroke'
                  onClick={() => {
                    dispatch(setCanFetchNewOrder(true));
                    navigate('/schedule');
                  }}
                  className='w-full py-4'
                >
                  {t('order.specialist_found.change_date')}
                </Button>
              </div>
            </>
          ) : (
            <div className='flex min-h-[60vh] flex-col items-center justify-start gap-6'>
              <h3 className='mt-2 text-center text-2xl font-medium text-gray-4'>
                {t('no_specialists_time')}
              </h3>
              <Link to='/schedule' className='w-full'>
                <Button variant='yellow' className='w-full py-4'>
                  {t('select_another_time')}
                </Button>
              </Link>
            </div>
          )}
        </Sheet>
      )}
    </Wrapper>
  );
};

export default SearchSpecialistsPage;
