import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';
import ReactGA from 'react-ga';

import { refreshTokens } from 'api/api';
import Header from 'components/header/Header';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import bgImg from 'images/backgrounds/bg2.webp';
import SupportEmailModal from 'modals/SupportEmailModal';
import { MainPage, MainSpecialistPage } from 'pages';
import AppRoutes from 'AppRoutes';
import { getTypedStorageItem, setTypedStorageItem } from 'utils/storage';
import { CountryCode } from 'utils/types';
import { logOutUser } from 'redux/actions';
import AcountDeletedModal from 'modals/AcountDeletedModal';

const ChangesSavedModal = lazy(() => import('modals/ChangesSavedModal'));
const TermsModal = lazy(() => import('modals/TermsModal'));
const ShareReferralModal = lazy(() => import('modals/ShareReferralModal'));
const SpecialistCanceledOrdedModal = lazy(
  () => import('modals/SpecialistCanceledOrdedModal'),
);
const CancelOrderModal = lazy(() => import('modals/CancelOrderModal'));
const RegisterModal = lazy(() => import('modals/RegisterModal'));

if (window.location.hostname !== 'dev.gotou') {
  ReactGA.initialize('G-6XQ97LYJWE', { testMode: true });
}

const App = () => {
  const { role } = useAppSelector((state) => state.user);
  const {
    changesSaved,
    specialistCanceledOrder,
    cancelOrder,
    supportEmail,
    terms,
    shareReferral,
    register,
  } = useAppSelector((state) => state.modals);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  useEffect(() => {
    if (
      window.location.protocol === 'http:' &&
      window.location.hostname !== 'localhost'
    ) {
      window.location.replace(
        `https://${window.location.hostname}${window.location.pathname}${window.location.search}`,
      );
    }
  }, []);

  useEffect(() => {
    const refreshToken = getTypedStorageItem('gotouRefreshToken');

    if (!refreshToken || role === 'guest' || pathname === '/login') return;
    const fetchTokens = async () => {
      try {
        await refreshTokens();
      } catch (error: any) {
        dispatch(logOutUser());
      }
    };

    fetchTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, role]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(`https://ipapi.co/country/`);
        if (response.ok) {
          const countryCode = await response.text();
          setTypedStorageItem('country', countryCode as CountryCode);
        }
      } catch (error) {}
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    const startTime = Date.now();

    const sendSessionDuration = () => {
      const endTime = Date.now();
      const sessionDuration = (endTime - startTime) / 1000; // in seconds
      ReactGA.event({
        category: 'User',
        action: 'Session Duration',
        value: sessionDuration,
      });
    };

    window.addEventListener('beforeunload', sendSessionDuration);

    return () => {
      window.removeEventListener('beforeunload', sendSessionDuration);
      sendSessionDuration();
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='/botox' element={<MainPage />} />
        <Route path='/specialist' element={<MainSpecialistPage />} />
        <Route
          path='*'
          element={
            <>
              <Header />
              {isSmallScreen ? (
                <AppRoutes />
              ) : (
                <div
                  className='flex min-h-screen justify-center bg-cover bg-no-repeat'
                  style={{ backgroundImage: `url(${bgImg})` }}
                >
                  <div className='-mt-[80px] max-h-fit rounded-[30px] bg-white/30 px-14 py-5 backdrop-blur-md sm:scale-[0.78]'>
                    <AppRoutes />
                  </div>
                </div>
              )}
            </>
          }
        />
      </Routes>
      <Suspense fallback={<div />}>
        {changesSaved.active && <ChangesSavedModal />}
        {specialistCanceledOrder.active && <SpecialistCanceledOrdedModal />}
        {cancelOrder.active && <CancelOrderModal />}
        {supportEmail.active && <SupportEmailModal />}
        {terms.active && <TermsModal />}
        {shareReferral.active && <ShareReferralModal />}
        {register.active && <RegisterModal />}
        <AcountDeletedModal />
      </Suspense>
      <Toaster
        toastOptions={{
          error: {
            duration: 2000,
          },
        }}
      />
    </>
  );
};

export default App;
