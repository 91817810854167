import React, { useEffect, useState } from 'react';
import ArrowLeft from 'images/main-page/Arrow_left.svg';
import ArrowRight from 'images/main-page/arrow_right.svg';
import UserReviewImg from 'images/main-page/foro_review.webp';
import UserReviewHairImg from 'images/main-page/hair-review.jpg';
import { motion, PanInfo } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import ReviewCard from './ReviewCard';
import { useTranslation } from 'react-i18next';

const ONE_SECOND = 1000;
const AUTO_DELAY = ONE_SECOND * 20;

const SPRING_OPTIONS = {
  type: 'spring',
  mass: 3,
  stiffness: 400,
  damping: 50,
};

type ReviewCardProps = {
  name: string;
  avatar?: string;
  time: string;
  reviewTitle?: string;
  reviewImg?: string;
  review: string;
};

const reviews: ReviewCardProps[] = [
  {
    name: 'Melissa P.',
    time: 'Nov 18th, 2024',
    review:
      'I am very satisfied with the result. I dared to do it for a long time - but this procedure simply exceeded all my expectations',
    reviewImg: window.location.href.includes('botox')
      ? UserReviewImg
      : UserReviewHairImg,
  },
  {
    name: 'Kate.K',
    time: 'Nov 27th, 2024',
    review:
      'This experience was incredible! I was hesitant for a while, but I’m so glad I went through with it. It surpassed all my hopes!',
    reviewTitle: 'I am impressed',
  },
  {
    name: 'Sara.L',
    time: 'Nov 30th, 2024',
    avatar: 'https://randomuser.me/api/portraits/women/8.jpg',
    review:
      'Amazing results! I had been considering this for a long time, and now I’m thrilled I took the leap. It was better than I imagined!',
  },
  {
    name: 'Linda.M',
    time: 'Nov 18th, 2024',
    avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
    review:
      'I couldn’t be happier with the outcome! After months of thinking, I finally did it, and it exceeded every expectation.',
  },
];

const getTransleX = (
  currentIndex: number,
  isLargeScreen: boolean,
  isExtraSmallScreen: boolean,
) => {
  if (isExtraSmallScreen) return `-${currentIndex * 280 - 50}px`;

  if (isLargeScreen) return `-${currentIndex * 425}px`;

  return `-${currentIndex * 380 + 200}px`;
};

const ReviewSection = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const duplicatedReviews = [...reviews, ...reviews];

  useEffect(() => {
    const intervalRef = setInterval(() => {
      if (currentIndex === 0) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
      }
    }, AUTO_DELAY);

    return () => clearInterval(intervalRef);
  }, [currentIndex]);

  const onDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) => {
    if (Math.abs(info.offset.x) > 100) {
      if (info.offset.x > 0) {
        handlePrev();
      } else {
        handleNext();
      }
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= reviews.length) {
        return 0; // Reset to the start after a short delay
      }
      return newIndex;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex < 0) {
        return reviews.length - 1; // Reset to the end after a short delay
      }
      return newIndex;
    });
  };

  const isLargeScreen = useMediaQuery({ query: '(min-width: 768px)' });
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 339px)' });

  const translateX = getTransleX(
    currentIndex,
    isLargeScreen,
    isExtraSmallScreen,
  );

  return (
    <section className='max-w-screen mx-[10px] flex w-full flex-col items-center overflow-hidden  rounded-[20px] bg-white px-[11px] pb-6 pt-24 md:mx-0 md:rounded-none'>
      <h3 className='mb-6 text-[2.81rem] leading-[4.219rem] md:mb-24'>
        {t('hairdresserPage.reviews.title')}
      </h3>
      <div className='flex flex-col items-center justify-center gap-6'>
        <motion.div
          drag='x'
          dragConstraints={{
            left: 0,
            right: 0,
          }}
          animate={{
            translateX: translateX,
          }}
          transition={SPRING_OPTIONS}
          onDragEnd={onDragEnd}
          className='flex max-w-[1440px] cursor-grab gap-20 active:cursor-grabbing md:gap-3'
        >
          {duplicatedReviews.map((review, idx) => (
            <motion.div
              key={idx}
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              transition={SPRING_OPTIONS}
            >
              <ReviewCard {...review} />
            </motion.div>
          ))}
        </motion.div>
        <div className='flex items-center justify-center gap-5'>
          <button onClick={handlePrev}>
            <img
              src={ArrowLeft}
              alt='Previous'
              className='transition-all active:scale-90'
              width={59}
              height={59}
            />
          </button>
          <button onClick={handleNext}>
            <img
              src={ArrowRight}
              alt='Next'
              className='transition-all active:scale-90'
              width={59}
              height={59}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ReviewSection;
