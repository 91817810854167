import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { motion, useAnimation } from 'framer-motion';
import CalendarItem from 'components/Calendar/CalendarItem';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import { Order } from 'utils/types';
import ReactDatePicker from 'react-datepicker';
import { FaCirclePlus } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router';
import { Input, SectionHeader, Wrapper } from 'ui';
import { BASE_URL } from 'api/api';
import LogoSad from 'images/icons/logo-sad.svg';
import { useTranslation } from 'react-i18next';

const OrdersPage = () => {
  const { t } = useTranslation();
  const { state: createdOrderMonthDateStamp } = useLocation();

  const { uid } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const controls = useAnimation(); // Framer Motion controls
  const [month, setMonth] = useState(
    createdOrderMonthDateStamp
      ? new Date(createdOrderMonthDateStamp)
      : new Date(),
  );
  const { loading, data: orderDays } = useApiRequest<Order[]>({
    endpoint: `${BASE_URL}orders/shortInfo/${uid}/${
      month.getMonth() + 1
    }/${month.getFullYear()}`,
    destructuringDataType: 'calendar',
    deps: month,
  });

  const CustomInput = React.forwardRef((props, ref) => (
    <Input ref={ref} {...props} className='w-[100px] text-center' readOnly />
  ));

  return (
    <Wrapper>
      <div className='flex justify-between smMax:sticky smMax:top-2 smMax:z-10'>
        <SectionHeader title={t('menu.orders')} />
        <ReactDatePicker
          selected={month}
          showMonthYearPicker
          dateFormat='MMMM / yyyy'
          minDate={new Date(2024, 0)}
          onChange={(date) => {
            setMonth(date!);
          }}
          monthClassName={() => 'text-[1rem] !rounded-[1rem]'}
          calendarClassName='shadow-md mr-5'
          customInput={<CustomInput />}
        />
      </div>
      {!loading && orderDays && orderDays?.length > 0 && (
        <motion.section
          className='mt-10 flex flex-col gap-8 pr-2 sm:max-h-[80vh] sm:min-h-[650px] sm:overflow-y-auto'
          animate={controls}
        >
          <div className='flex flex-col gap-8 border-b '>
            {orderDays.map((orderDay, index) => (
              <motion.div
                key={`${orderDay.day}-${index}`}
                variants={{
                  hidden: { opacity: 0.2, y: 15 },
                }}
                initial='hidden'
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ ease: [0.78, 0.14, 0.15, 0.86] }}
              >
                <CalendarItem
                  date={new Date(orderDay.day)}
                  orders={orderDay.orders}
                />
              </motion.div>
            ))}
          </div>
        </motion.section>
      )}
      {!loading && (orderDays?.length ?? 0) === 0 && (
        <div className='mt-20 flex flex-col items-center justify-center gap-3'>
          <img src={LogoSad} alt='sad logo' width={70} height={70} />
          <h2 className='text-center text-2xl font-medium'>
            {t('order.no_orders')}
          </h2>
          <p className='text-center text-secondary'>
            {t('order.no_orders.description')}
          </p>
        </div>
      )}
      {ReactDOM.createPortal(
        <FaCirclePlus
          className='fixed bottom-10 right-2 z-10 cursor-pointer rounded-full bg-white text-4xl text-accent shadow-md transition-all hover:shadow-lg sm:hidden'
          onClick={() => navigate('/specialist/create-order')}
          size={50}
          title='Create order'
        />,
        document.getElementById('portal')!,
      )}
      <FaCirclePlus
        className='fixed bottom-20 right-24 z-10 hidden cursor-pointer rounded-full bg-white text-4xl text-accent shadow-md transition-all hover:shadow-lg sm:block'
        onClick={() => navigate('/specialist/create-order')}
        size={50}
        title='Create order'
      />
    </Wrapper>
  );
};

export default OrdersPage;
