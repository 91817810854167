import React from 'react';
import Img from 'images/main-page/woman-preparing-cosmetic-surgery 2.webp';
import { Button } from 'ui';
import { setUserRole } from 'redux/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { logOut } from 'api/login';
import toast from 'react-hot-toast';
import AcceptToast from 'modals/AcceptToast';
import { useAppSelector } from 'hooks/useAppSelector';

const SkinIssueSection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, role, uid } = useAppSelector((state) => state.user);

  const handleLogin = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Book an appointment Button',
      label: 'Hair Issue Section',
    });

    if (!isLoggedIn || role !== 'specialist') {
      dispatch(setUserRole('client'));
      navigate('/category');
    } else {
      AcceptToast({
        saveButtonText: t('menu.logout'),
        cancelButtonText: t('client.remove_favorite.no'),
        text: t('message.login_specialist'),
        handleSaveClick: async () => {
          await logOut(
            uid,
            role as 'client' | 'specialist',
            dispatch,
            navigate,
          );
          dispatch(setUserRole('client'));
          navigate('/login');
          toast.dismiss();
        },
        duration: 100000,
      });
    }
  };

  return (
    <section className='main-page-wrapper flex max-w-[1440px] flex-col md:flex-row md:items-center md:justify-between md:gap-24'>
      <img
        src={Img}
        alt='woman-preparing-cosmetic-surgery'
        loading='lazy'
        className='my-10 hidden max-w-[40%] md:block'
      />
      <div className='font-inter'>
        <h3 className='mb-10 font-sans text-[2.81rem] leading-[4.219rem] text-main.primary'>
          {t('hairdresserPage.skinIssues.heading')}
        </h3>
        <p className='mb-4 text-md2 text-main.secondary'>
          {t('hairdresserPage.skinIssues.paragraph1')}
        </p>
        <p className='text-md2 text-main.secondary'>
          {t('hairdresserPage.skinIssues.paragraph2')}
        </p>
        <img
          src={Img}
          alt='woman-preparing-cosmetic-surgery'
          loading='lazy'
          className='my-10 block md:hidden'
        />
        <p className='mb-4 text-md2 text-main.secondary'>
          {t('hairdresserPage.skinIssues.paragraph3')}
        </p>
        <p className='mb-6 text-md2 text-main.secondary'>
          {t('hairdresserPage.skinIssues.paragraph4')}
        </p>
        <Button variant='outline-botox' onClick={handleLogin}>
          {t('hairdresserPage.buttons.book-appointment')}
        </Button>
      </div>
    </section>
  );
};

export default SkinIssueSection;
