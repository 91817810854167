import React from 'react';
import { GoChevronRight } from 'react-icons/go';

type SettingsItemProps = {
  settingName: string;
  onClick: () => void;
  disabled: boolean;
};

const SettingsItem = ({
  settingName,
  onClick,
  disabled,
}: SettingsItemProps) => {
  return (
    <li
      className={`${
        disabled ? '' : 'cursor-pointer hover:underline'
      } flex select-none justify-between px-2 py-2 transition-all `}
      onClick={onClick}
    >
      {settingName} <GoChevronRight size={25} />
    </li>
  );
};

export default SettingsItem;
