import React from 'react';
import { Input } from 'ui';
import DeleteIcon from 'images/emoji/Delete.svg';
import { toast } from 'react-hot-toast';
import { convertTimeToMinutes } from 'utils/time';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

type WorkTimeSelectorProps = {
  workStartTime: string;
  setWorkStartTime: (value: string) => void;
  setWorkEndTime: (value: string) => void;

  workEndTime: string;
  setRemoveTimeSlotIndex?: React.Dispatch<React.SetStateAction<number>>;
  showDeleteIcon: boolean;
  timeSlotIndex: number;
  removeTimeSlot: any;
};

const WorkTimeSelector = ({
  timeSlotIndex,
  workStartTime,
  setWorkStartTime,
  workEndTime,
  showDeleteIcon,
  removeTimeSlot,
  setWorkEndTime,
}: WorkTimeSelectorProps) => {
  const { t } = useTranslation();
  const handleChangeStartTime = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value > workEndTime && workEndTime !== '') {
      toast.dismiss();
      toast.error('Start time cannot be later than end time');
    } else {
      setWorkStartTime(event.target.value);
    }
  };

  const handleRemoveTimeSlot = () => {
    removeTimeSlot(timeSlotIndex);
  };

  const handleChangeEndTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      convertTimeToMinutes(event.target.value) <
      convertTimeToMinutes(workStartTime)
    ) {
      toast.dismiss();
      toast.error('End time cannot be earlier than start time');
    } else {
      setWorkEndTime(event.target.value);
    }
  };

  return (
    <motion.div
      className='mx-auto mt-6 flex justify-center gap-4 smMax:gap-2'
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.15, type: 'tween' }}
    >
      <Input
        type='time'
        value={workStartTime}
        required
        onChange={handleChangeStartTime}
        min='06:00'
        max={workEndTime || '22:00'}
        label={t('schedule.working_hours_from')}
        className='mt-3 w-[110px] min-w-fit rounded-md border border-gray-300 bg-white p-2 text-center outline-none hover:border-accent active:border-accent xs:w-auto'
      />
      <Input
        type='time'
        value={workEndTime}
        min={workStartTime}
        max='22:00'
        required
        onChange={handleChangeEndTime}
        label={t('schedule.working_hours_to')}
        className='mt-3 w-[110px] min-w-fit rounded-md border border-gray-300 bg-white p-2 text-center outline-none hover:border-accent active:border-accent  xs:w-auto'
      />
      {showDeleteIcon && (
        <motion.img
          src={DeleteIcon}
          alt='delete button'
          className='cursor-pointer'
          title='Remove time slot'
          aria-label='Remove time slot'
          onClick={handleRemoveTimeSlot}
          width={20}
          height={20}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
        />
      )}
    </motion.div>
  );
};

export default WorkTimeSelector;
