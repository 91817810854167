import React, { lazy, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CategorySkeleton from 'components/Category/CategorySkeleton';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { clearCategories, setCategories } from 'redux/categorySlice';
import { SectionHeader, Spinner, Wrapper } from 'ui';
import CategoryItem from 'components/Category/CategoryItem';
import { Link } from 'react-router-dom';
import { BASE_URL } from 'api/api';
import { BiSolidPencil } from 'react-icons/bi';
import { Category } from 'utils/types';
import { toggleModal } from 'redux/modalSlice';
import { useTranslation } from 'react-i18next';
import LocationSelector from 'components/Category/LocationSelector';
import { resetUserLocation } from 'redux/userSlice';

const NoSpecialistsModal = lazy(() => import('modals/NoSpecialistsModal'));

const CategoriesPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector((state) => state.category);
  const {
    address,
    role,
    city,
    country,
    clientSubRole,
    specSubRole,
    isLoggedIn,
  } = useAppSelector((state) => state.user);
  const { active } = useAppSelector((state) => state.modals.noSpecInLocation);

  const redirectTo =
    role === 'specialist' ? '/specialist/services/select' : '/category';
  const apiURL =
    role === 'specialist' && isLoggedIn
      ? 'categories'
      : `categories/byLocation/${city}/${country}`;

  const { loading, data: fetchedCategories } = useApiRequest<Category[]>({
    conditionToFetch: !!city,
    endpoint: `${BASE_URL}${apiURL}`,
    destructuringDataType: 'categories',
    deps: city,
  });

  useEffect(() => {
    if (!loading && fetchedCategories && fetchedCategories.length > 0) {
      dispatch(setCategories(fetchedCategories));

      const allCategoriesDisabled = fetchedCategories.every(
        (category) => !category.enable,
      );
      if (clientSubRole === 'full' && allCategoriesDisabled) {
        dispatch(toggleModal({ modalType: 'noSpecInLocation' }));
      }
    }
  }, [loading, fetchedCategories, dispatch, clientSubRole]);

  const renderSubtitle = () => {
    if (clientSubRole === 'full' && isLoggedIn) {
      return (
        <Link to='/location' className='underline hover:no-underline'>
          {address} <BiSolidPencil className='inline' />
        </Link>
      );
    }

    if (address && !isLoggedIn) {
      return (
        <button
          onClick={() => {
            dispatch(resetUserLocation());
            dispatch(clearCategories());
          }}
        >
          {address} <BiSolidPencil className='inline' />
        </button>
      );
    }

    if (specSubRole === 'full') {
      return t('add_service.description');
    }
  };

  return (
    <Wrapper className='!px-4'>
      <SectionHeader
        title={role !== 'specialist' ? t('category') : t('services.add')}
        subtitle={renderSubtitle()}
        wrapperClassName='smMax:!-mx-2'
      />
      {loading ? (
        <ul className='mt-6 grid grid-cols-2 justify-items-stretch gap-5 sm:max-h-[90vh] sm:overflow-y-auto xs:gap-2 '>
          <CategorySkeleton />
          <CategorySkeleton />
        </ul>
      ) : (
        categories.length > 0 && (
          <ul className='mt-6 grid grid-cols-2 justify-items-stretch gap-5 sm:max-h-[90vh] sm:overflow-y-auto xs:gap-2 '>
            {categories.map((category) => (
              <CategoryItem
                key={category.id}
                category={category}
                link={redirectTo}
              />
            ))}
          </ul>
        )
      )}
      {!loading && !city && <LocationSelector />}
      <Suspense fallback={<Spinner />}>
        {active &&
          ReactDOM.createPortal(
            <NoSpecialistsModal />,
            document.getElementById('root') as Element,
          )}
      </Suspense>
    </Wrapper>
  );
};

export default CategoriesPage;
