import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Service } from 'utils/types';
import { logOutUser } from './actions';

type SpecialistServicesState = {
  services: Service[];
};

const initialState: SpecialistServicesState = {
  services: [],
};

const specialistServicesSlice = createSlice({
  name: 'specialistServices',
  initialState,
  reducers: {
    setServices: (state, { payload }: PayloadAction<Service[]>) => {
      state.services = payload;
    },
    resetServices: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(logOutUser, () => initialState);
  },
});

export const { setServices, resetServices } = specialistServicesSlice.actions;

export default specialistServicesSlice.reducer;
