import React, { useEffect, useState } from 'react';
import SectionTitle from './SectionTitle';
import ReviewItem from './ReviewItem';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { useMediaQuery } from 'react-responsive';
import { PanInfo, motion } from 'framer-motion';
import { mockReviewsBusiness } from 'utils/mock';
import { useTranslation } from 'react-i18next';

const ONE_SECOND = 1000;
const AUTO_DELAY = ONE_SECOND * 20;

const getTransleX = (currentIndex: number, isLargeScreen: boolean) => {
  if (isLargeScreen) {
    if (currentIndex === 0) return `20px`;
    return `-${currentIndex * 340}px`;
  }

  if (currentIndex === 0) return `60px`;
  return `-${currentIndex * 385}px`;
};

const ReviewsSection = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const duplicatedReviews = [...mockReviewsBusiness, ...mockReviewsBusiness];

  useEffect(() => {
    const intervalRef = setInterval(() => {
      if (currentIndex === 0) {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % mockReviewsBusiness.length,
        );
      }
    }, AUTO_DELAY);

    return () => clearInterval(intervalRef);
  }, [currentIndex]);

  const onDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) => {
    if (Math.abs(info.offset.x) > 100) {
      if (info.offset.x > 0) {
        handlePrev();
      } else {
        handleNext();
      }
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= mockReviewsBusiness.length) {
        return 0; // Reset to the start after a short delay
      }
      return newIndex;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex < 0) {
        return mockReviewsBusiness.length - 1; // Reset to the end after a short delay
      }
      return newIndex;
    });
  };

  const isLargeScreen = useMediaQuery({ query: '(min-width: 768px)' });

  const translateX = getTransleX(currentIndex, isLargeScreen);

  return (
    <section className='main-page-wrapper section-ellipse-bottom' id='reviews'>
      <div className='content flex flex-col gap-14 overflow-hidden'>
        <SectionTitle
          title={t('businessPage.reviews.title')}
          description={t('businessPage.reviews.description')}
        />

        <motion.div
          drag='x'
          dragConstraints={{
            left: 0,
            right: 0,
          }}
          animate={{
            translateX: translateX,
          }}
          onDragEnd={onDragEnd}
          className='mt-10 flex max-w-[1440px] cursor-grab gap-20 active:cursor-grabbing md:gap-3 '
        >
          {duplicatedReviews.map((review, idx) => (
            <motion.div
              key={idx}
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <ReviewItem {...review} />
            </motion.div>
          ))}
        </motion.div>
        <div className=' flex items-center justify-center gap-5'>
          <button onClick={handlePrev}>
            <FaChevronLeft
              className='rounded-full border border-[#5E5B5B99] p-2 text-[#5E5B5B99] transition-all hover:border-accent hover:bg-accent hover:text-white hover:shadow active:scale-90'
              size={48}
            />
          </button>
          <button onClick={handleNext}>
            <FaChevronRight
              className='rounded-full border border-[#5E5B5B99] p-2 text-[#5E5B5B99] transition-all hover:border-accent hover:bg-accent hover:text-white hover:shadow active:scale-90'
              size={48}
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;
