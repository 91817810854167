import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button, LanguageDropDown } from 'ui';
import Navigation from './Navigation';

import InstagramIcon from 'images/main-page/icon_instagram.svg';
import MasterCardIcon from 'images/icons/icon.mastercard.svg';
import VisaIcon from 'images/icons/icon.visa.svg';
import StripeIcon from 'images/icons/Srtipe.svg';

type NavigationProps = {
  links: {
    title: string;
    navigationRole?: 'client' | 'specialist';
    href: string;
  }[];
  role: 'client' | 'specialist';
};

const FooterSection = ({ links, role }: NavigationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <footer className='main-page-wrapper mb-12 w-full max-w-[1440px] font-sans'>
      <nav className=' mb-12 flex flex-col items-center justify-center gap-3 sm:flex-row md:justify-start md:gap-6 xl:gap-12'>
        <Navigation links={links} />
        <div className='flex items-center justify-center gap-12 md:justify-start xs:flex-col xs:gap-0'>
          <LanguageDropDown />
          <Button
            variant='text'
            className='hover:underline'
            onClick={() => navigate(role === 'client' ? '/' : '/specialist')}
          >
            For {role}
          </Button>
        </div>
      </nav>

      <div className='flex flex-col-reverse items-center justify-between gap-7 border-t border-[#D9D9D9] pt-4 md:flex-row md:items-start md:pt-8'>
        <div className='flex flex-col items-center justify-start md:items-start md:justify-start'>
          <img
            src='/Logo-goto.svg'
            alt='GoToU Logo'
            loading='lazy'
            className='aspect-[280/130] w-full max-w-[250px] md:max-w-[280px]'
          />
          <p className='mt-1 text-sm text-[#9D9D9D]'>
            © 2024 GotoU Labs. All rights reserved
          </p>
          <a href='/privacy' className='mt-2 text-secondary hover:underline'>
            {t('hairdresserPage.navigation.terms')}
          </a>
          <div className='mt-2 text-center text-secondary md:text-left md:text-sm'>
            <p>GotoU-labs, Inc.</p>
            <p>
              651 N Broad St, Suite 201, Country of New Castle, Middletown,
              Delaware 19709
            </p>
            <p>+18778061747</p>
          </div>
        </div>
        <div className='flex flex-col gap-11'>
          <div className='flex justify-center gap-5'>
            <a
              href='https://www.instagram.com/gotou_app?igsh=MXFyMWt2eTExZnJqaw=='
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={InstagramIcon}
                alt='Instagram Icon'
                loading='lazy'
                width={66}
                height={66}
              />
            </a>
            {/* <a
              href='https://www.facebook.com/'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={FacebookIcon}
                alt='Facebook Icon'
                loading='lazy'
                width={66}
                height={66}
              />
            </a> */}
          </div>
          <div className='flex w-full flex-col items-center'>
            <div className='flex items-center gap-1  text-[#1B1B1BB2]'>
              Povered by <img src={StripeIcon} alt='Stripe icon' />
            </div>
            <div className='flex w-full items-center justify-evenly'>
              <img src={MasterCardIcon} alt='MasterCard icon' />
              <img src={VisaIcon} alt='Visa icon' />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
