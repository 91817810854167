import React from 'react';
import { FaStar } from 'react-icons/fa';
import SpecialistAvatar from './UserAvatar';
import { removeFavoriteSpecialist } from 'api/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNavigate } from 'react-router';
import { Button } from 'ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetState } from 'redux/createOrderPageSlice';

type FavoriteSpecialistCardProps = {
  specialist: {
    firstName: string;
    lastName: string;
    subcategory: string;
    profilePhoto: string;
    stringSpecId: string;
    rating: number;
    picsCnt: number;
    stringId: string;
    reviewsCnt: number;
  };
  setChangeState: React.Dispatch<React.SetStateAction<boolean>>;
};

const FavoriteSpecialistCard = ({
  specialist,
  setChangeState,
}: FavoriteSpecialistCardProps) => {
  const { t } = useTranslation();
  const { uid } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { firstName, lastName, profilePhoto, stringId, rating, reviewsCnt } =
    specialist;

  const handleRemoveLike = async () => {
    setChangeState((prevState: boolean) => !prevState);
    const response = await removeFavoriteSpecialist(uid, stringId);
    !response && setChangeState((prevState: boolean) => !prevState);
  };

  const handleBook = () => {
    dispatch(resetState());
    navigate('/create-order', { state: specialist });
  };

  return (
    <section className='flex w-full min-w-[140px] max-w-[160px] flex-col items-center justify-between gap-2 rounded-lg border border-secondary p-4 transition-all hover:border-accent'>
      <SpecialistAvatar
        src={profilePhoto}
        isFavourite={true}
        handleLikeSpecialist={handleRemoveLike}
        circleAvatar
      />
      <h4 className='cursor-pointer text-center font-medium text-primary'>
        {firstName} {lastName}
      </h4>
      <div className='flex gap-2'>
        <FaStar className='text-accent' size={20} />
        <p className='text-secondary'>{rating}</p>
      </div>
      <p className='text-secondary'>
        {reviewsCnt} {t('order.specialist_found.reviews')}
      </p>
      <Button variant='yellow' className='w-full' onClick={handleBook}>
        {t('my_orders.book')}
      </Button>
    </section>
  );
};

export default FavoriteSpecialistCard;
