import React from 'react';
import { Button } from 'ui';
import { setUserRole } from 'redux/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { useAppSelector } from 'hooks/useAppSelector';
import AcceptToast from 'modals/AcceptToast';
import { logOut } from 'api/login';
import toast from 'react-hot-toast';

const HairIssueSection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, role, uid } = useAppSelector((state) => state.user);

  const handleLogin = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Book an appointment Button',
      label: 'Hair Issue Section',
    });

    if (!isLoggedIn) {
      dispatch(setUserRole('client'));
      navigate('/category');
    } else {
      if (role === 'client') {
        navigate('/category');
      } else {
        AcceptToast({
          saveButtonText: t('menu.logout'),
          cancelButtonText: t('client.remove_favorite.no'),
          text: t('message.login_specialist'),
          handleSaveClick: async () => {
            await logOut(
              uid,
              role as 'client' | 'specialist',
              dispatch,
              navigate,
            );
            dispatch(setUserRole('client'));
            navigate('/login');
            toast.dismiss();
          },
          duration: 100000,
        });
      }
    }
  };

  return (
    <section className='!mt-0 w-full rounded-b-[50px] bg-white px-3 pb-10 pt-20 md:pb-28'>
      <div className='mx-auto flex max-w-[1440px] flex-col gap-10 font-inter md:items-center'>
        <h3 className='mx-auto max-w-[450px] text-center font-sans text-[2.81rem] leading-[4.219rem] text-main.primary '>
          {t('hairdresserPage.hairIssues.heading')}
        </h3>
        <div className='flex flex-col gap-8 md:flex-row md:justify-around'>
          <div className='max-w-[600px] flex-1'>
            <p className='mb-4 text-md2 text-main.secondary'>
              {t('hairdresserPage.hairIssues.paragraph1')}
            </p>
            <p className='text-md2 text-main.secondary'>
              {t('hairdresserPage.hairIssues.paragraph2')}
            </p>
          </div>
          <div className='max-w-[600px] flex-1'>
            <p className='mb-4 text-md2 text-main.secondary'>
              {t('hairdresserPage.hairIssues.paragraph3')}
            </p>
            <p className='mb-6 text-md2 text-main.secondary'>
              {t('hairdresserPage.hairIssues.paragraph4')}
            </p>
            <p className='mb-6 text-md2 text-main.secondary'>
              {t('hairdresserPage.hairIssues.paragraph5')}
            </p>
          </div>
        </div>
        <Button
          variant='outline-botox'
          onClick={handleLogin}
          className='max-w-[260px]'
        >
          {t('hairdresserPage.buttons.book-appointment')}
        </Button>
      </div>
    </section>
  );
};

export default HairIssueSection;
