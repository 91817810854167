import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logOutUser } from './actions';

export type Category = {
  enable?: boolean;
  id: string;
  name: string;
  imgURL: string;
  stringId: string;
  iconeURL?: string;
};

type CategoryState = {
  categories: Category[];
  subcategories: Category[];
  services: Category[];
  selectedCategoryName: string;
  selectedSubcategoryName: string;
  selectedServiceName: string;
};

const initialState: CategoryState = {
  categories: [],
  subcategories: [],
  services: [],
  selectedCategoryName: '',
  selectedSubcategoryName: '',
  selectedServiceName: '',
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategories: (state, { payload }: PayloadAction<Category[]>) => {
      state.categories = payload;
    },
    clearCategories: (state) => {
      state.categories = [];
    },
    setSubcategories: (state, { payload }: PayloadAction<Category[]>) => {
      state.subcategories = payload;
    },
    clearSubcategories: (state) => {
      state.subcategories = [];
    },
    setServices: (state, { payload }: PayloadAction<Category[]>) => {
      state.services = payload;
    },
    setSelectedCategoryName: (state, { payload }: PayloadAction<string>) => {
      state.selectedCategoryName = payload;
    },
    setSelectedSubcategoryName: (state, { payload }: PayloadAction<string>) => {
      state.selectedSubcategoryName = payload;
    },
    setSelectedServiceName: (state, { payload }: PayloadAction<string>) => {
      state.selectedServiceName = payload;
    },
    clearServices: (state) => {
      state.services = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(logOutUser, () => initialState);
  },
});

export const {
  setCategories,
  clearCategories,
  setSubcategories,
  clearSubcategories,
  setServices,
  clearServices,
  setSelectedCategoryName,
  setSelectedSubcategoryName,
  setSelectedServiceName,
} = categorySlice.actions;

export default categorySlice.reducer;
