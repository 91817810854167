import React from 'react';
import { Variants, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { getTypedStorageItem } from 'utils/storage';

const listOfCategories = (t: any) => {
  return t('hairdresserPage.servicesList', { returnObjects: true }) as string[];
};

const slideAnimation: Variants = {
  animate: {
    x: ['10%', '-10%', '10%'],
    transition: {
      duration: 50,
      ease: 'linear',
      repeat: Infinity,
    },
  },
};

const ServicesSection = () => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isWideTextLanguage = getTypedStorageItem('selectedLanguage') === 'de';

  return (
    <section className='main-page-wrapper overflow-hidden py-10'>
      {isSmallScreen ? (
        <div
          className={`grid grid-cols-2 gap-4 xs:grid-cols-1 ${
            isWideTextLanguage ? 'grid-cols-1' : ''
          }`}
        >
          {listOfCategories(t).map((category, i) => (
            <div
              key={i}
              className={`flex select-none items-center  justify-center rounded-[20px] border border-main.border px-4 py-14 ${
                i === 4 && !isWideTextLanguage ? 'col-span-2 xs:col-span-1' : ''
              }`}
            >
              <p className='text-center font-sans text-xl italic text-[#3C3E38] md:text-3xl'>
                {t(category)}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <motion.div
          className='flex w-[200%] space-x-16 overflow-hidden md:flex'
          variants={slideAnimation}
          animate='animate'
        >
          {Array(2)
            .fill(listOfCategories(t))
            .flat()
            .map((category, index) => (
              <div
                key={index}
                className='flex aspect-[16/6] select-none items-center justify-center whitespace-nowrap rounded-[20px] border border-main.border px-7 py-20'
              >
                <p className='font-sans text-3xl italic text-[#3C3E38]'>
                  {t(category)}
                </p>
              </div>
            ))}
        </motion.div>
      )}
    </section>
  );
};

export default ServicesSection;
