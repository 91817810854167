import React, { useCallback, useState } from 'react';
import SectionLabel from './SectionLabel';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useAppSelector';
import { convertMsToTime } from 'utils/time';
import ReactDatePicker from 'react-datepicker';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import CalendarIcon from 'images/emoji/Calendar days.svg';
import {
  setDateStamp,
  setIsTimeSelected,
  setMonth,
  setHoursTimeStamp,
  setTimeSlotId,
} from 'redux/createOrderPageSlice';
import { TimeSlotsList, HeaderDatePicker, TimeSpecificOrderButton } from 'ui';
import { Order, SpecialistWithTimeSlots } from 'utils/types';
import { getTypedStorageItem } from 'utils/storage';
import { useApiRequest } from 'hooks/useApiRequest';
import { BASE_URL } from 'api/api';

type DateSectionProps = {
  allowedDays: number[];
  specialistOrders: Order['orders'];
  toggleDatePicker: () => void;
  isDatePickerOpen: boolean;
};

const variants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
    type: 'tween',
  },
  closed: { opacity: 0, height: 0, type: 'tween' },
};

const nextMonth = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 2,
  0,
); // Months are 0-indexed

const DateSection = ({
  allowedDays,
  toggleDatePicker,
  specialistOrders,
  isDatePickerOpen,
}: DateSectionProps) => {
  const { t } = useTranslation();

  const { chosenServices, isTimeSelected, dateStamp, month } = useAppSelector(
    (state) => state.createOrderPage,
  );
  const { role } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [isAnimating, setIsAnimating] = useState(false);

  const locale = getTypedStorageItem('selectedLanguage') || 'en';

  const year = new Date().getFullYear();
  const dateFrom = `${year}-${month}-01`;
  const dateTo = `${year}-${month}-31`;

  const { data: specialistTimeSlots } = useApiRequest<SpecialistWithTimeSlots>({
    conditionToFetch:
      chosenServices.length > 0 && !!chosenServices[0]?.stringId,
    endpoint: `${BASE_URL}time-slot/by-serviceid/${chosenServices[0]?.stringId}/date-range?dateFrom=${dateFrom}&dateTo=${dateTo}`,
    deps: [chosenServices[0]?.stringId, month],
  });

  const timeSlots = useCallback(() => {
    if (!specialistTimeSlots || specialistTimeSlots.length === 0) {
      return [];
    }

    // Format the dateStamp as a date without time (year, month, day)
    const targetDate = dateStamp
      ? new Date(dateStamp).setHours(0, 0, 0, 0)
      : null;

    const now = Date.now();

    // Filter and map the timeslots based on the date comparison
    return specialistTimeSlots[0].timeSlots.filter((timeSlot) => {
      const timeSlotDate = new Date(timeSlot.startTime).setHours(0, 0, 0, 0);
      const timeSlotTime = new Date(timeSlot.startTime).getTime();

      return timeSlotDate === targetDate && timeSlotTime > now;
    });
  }, [specialistTimeSlots, dateStamp]);

  const handleSelectDate = (date: Date | null) => {
    dispatch(setDateStamp(date!?.getTime()));
    dispatch(setHoursTimeStamp(null));
    dispatch(setIsTimeSelected(false));
  };

  const handleSelectTimeSlot = (time: Date, timeSlotId: number) => {
    dispatch(setTimeSlotId(timeSlotId));
    dispatch(setDateStamp(time.getTime()));
    dispatch(setHoursTimeStamp(time.getTime()));
    dispatch(setIsTimeSelected(true));
  };

  const description =
    dateStamp && chosenServices[0]
      ? `${new Date(dateStamp).toLocaleDateString([], {
          month: 'short',
          weekday: 'short',
          day: 'numeric',
          ...(isTimeSelected && {
            hour: 'numeric',
            minute: 'numeric',
          }),
        })} - ${
          isTimeSelected
            ? convertMsToTime(dateStamp + chosenServices[0]?.gssDto?.duration)
            : ''
        }`
      : '';

  return (
    <>
      <SectionLabel
        title={t('create_order.date_and_time')}
        description={description}
        icon={CalendarIcon}
        enable={isDatePickerOpen}
        onClick={toggleDatePicker}
      />
      <AnimatePresence>
        {isDatePickerOpen && (
          <>
            <li className=' flex flex-col items-center px-5'>
              <ReactDatePicker
                dateFormat='MMMM d, yyyy h:mm aa'
                filterDate={(date) => allowedDays.includes(date.getDate())}
                inline
                maxDate={nextMonth}
                minDate={new Date()}
                onChange={handleSelectDate}
                onMonthChange={(date) =>
                  dispatch(setMonth(date.getMonth() + 1))
                }
                renderCustomHeader={HeaderDatePicker}
                selected={dateStamp ? new Date(dateStamp) : null}
                wrapperClassName='transition-all focus:outline-none '
                shouldCloseOnSelect={false}
              />
              {dateStamp && (
                <TimeSlotsList
                  timeSlots={timeSlots()}
                  handleSelectTime={handleSelectTimeSlot}
                />
              )}
            </li>
            {role === 'specialist' &&
              dateStamp &&
              specialistOrders.length > 0 && (
                <motion.ul
                  className={`my-4 flex max-h-[30vh] flex-col gap-2 ${
                    isAnimating ? 'overflow-hidden' : 'overflow-y-auto'
                  } px-5 pr-2`}
                  variants={variants}
                  initial='closed'
                  animate={specialistOrders.length > 0 ? 'open' : 'closed'}
                  exit='closed'
                  onAnimationStart={() => setIsAnimating(true)}
                  onAnimationComplete={() => setIsAnimating(false)}
                >
                  <li>
                    <p className='text-sm text-secondary'>
                      {t('orders_on')}{' '}
                      {new Date(dateStamp).toLocaleString(locale, {
                        weekday: 'long',
                        day: 'numeric',
                      })}
                    </p>
                  </li>
                  {specialistOrders?.map((order) => (
                    <TimeSpecificOrderButton
                      url={`/specialist/orders/${order.idStr}`}
                      order={order}
                      key={order.idStr}
                    />
                  ))}
                </motion.ul>
              )}
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default DateSection;
