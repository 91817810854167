import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useApiRequest } from 'hooks/useApiRequest';
import {
  setSelectedSubcategoryName,
  setSubcategories,
} from 'redux/categorySlice';
import { setSubcategoryId } from 'redux/serviceIdSlice';
import { Button, NoCategoryFound, SectionHeader, Wrapper } from 'ui';
import { BASE_URL } from 'api/api';
import { Category } from 'utils/types';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { buttonsAppearVariants } from '../customer/ServicesPage';

const SubCategoriesPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { categoryId } = useAppSelector((state) => state.service);
  const { subcategories, selectedCategoryName } = useAppSelector(
    (state) => state.category,
  );

  const { role, city, country } = useAppSelector((state) => state.user);

  const apiURL =
    role === 'client' ? `subcategories/${city}/${country}` : 'subcategories';

  const { loading, data: fetchedSubcategories } = useApiRequest<Category[]>({
    endpoint: `${BASE_URL}categories/${categoryId}/${apiURL}`,
    destructuringDataType: 'subcategories',
  });

  const handleSelectSubcategory = (subcategory: Category) => {
    if (subcategory.enable !== undefined && !subcategory.enable) {
      return;
    }
    dispatch(setSubcategoryId(subcategory.stringId));
    dispatch(setSelectedSubcategoryName(subcategory.name));

    if (role === 'specialist') {
      navigate(`/specialist/services/add/${subcategory.stringId}`, {
        state: subcategory.name,
      });
      return;
    }
    navigate(`/services/${subcategory.stringId}`);
  };

  useEffect(() => {
    if (!loading && fetchedSubcategories) {
      dispatch(setSubcategories(fetchedSubcategories));
    }
  }, [loading, fetchedSubcategories, dispatch]);

  return (
    <Wrapper>
      <SectionHeader
        title={selectedCategoryName}
        subtitle={t('add_service.subcategory_description')}
      />
      <div className='mt-6 flex flex-col sm:max-h-[70vh] sm:overflow-y-auto'>
        {loading ? (
          <Button skeleton>Category</Button>
        ) : (
          subcategories.length > 0 &&
          subcategories.map((subcategory, index) => (
            <motion.div
              key={subcategory.stringId}
              variants={buttonsAppearVariants}
              initial='hidden'
              animate='visible'
              transition={{ delay: index * 0.05 }}
            >
              <Button
                className={`my-2 flex w-full items-center py-3 text-left ${
                  subcategory.enable !== undefined && !subcategory.enable
                    ? 'justify-between'
                    : '!border-accent-50 hover:!border-accent'
                }`}
                variant='grayStroke'
                disabled={
                  subcategory.enable !== undefined && !subcategory.enable
                }
                onClick={() => handleSelectSubcategory(subcategory)}
              >
                {subcategory.enable !== undefined && subcategory.enable && (
                  <img
                    src={subcategory.iconeURL}
                    alt={`${subcategory.name} icon`}
                    width={14}
                    height={14}
                    className='mr-4'
                  />
                )}
                {subcategory.name}
                {subcategory.enable !== undefined && !subcategory.enable && (
                  <p className='text-right font-medium text-primary'>
                    {t('coming_soon')}
                  </p>
                )}
              </Button>
            </motion.div>
          ))
        )}
        {!loading && subcategories.length === 0 && <NoCategoryFound />}
      </div>
    </Wrapper>
  );
};

export default SubCategoriesPage;
