import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';

import { setUserLocation } from 'redux/userSlice';
import { BASE_URL } from 'api/api';

import { useApiRequest } from 'hooks/useApiRequest';
import { useAppDispatch } from 'hooks/useAppDispatch';

import { Button, toastWithDismiss } from 'ui';

type LocationPayload = {
  city: string;
  country: string;
};

const LocationSelector = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [LIBRARIES] = useState<Libraries>(['places']);
  const [location, setLocation] = useState<LocationPayload>({
    city: '',
    country: '',
  });
  const [selectedCoordinates, setSelectedCoordinates] = useState({
    lat: 0,
    lng: 0,
  });

  const { loading, data: avaliableLocations } = useApiRequest<
    LocationPayload[]
  >({
    endpoint: `${BASE_URL}address/findOurLocations`,
    destructuringDataType: 'dtos',
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: LIBRARIES,
  });

  const handleSelectNewLocation = async (location: LocationPayload) => {
    setLocation(location);
    try {
      const results = await getGeocode({
        address: `${location.city}, ${location.country}`,
      });
      if (results.length === 0) {
        throw new Error('ZERO_RESULTS');
      }
      const LatLng = await getLatLng(results[0]);
      setSelectedCoordinates(LatLng);
    } catch (error: any) {
      if (error.message === 'ZERO_RESULTS') {
        toastWithDismiss(
          'No results found for the specified location',
          'error',
        );
      } else {
        toastWithDismiss(
          'An error occurred while fetching the location',
          'error',
        );
      }
    }
  };

  const handleCheckCity = () => {
    if (avaliableLocations?.length) {
      dispatch(
        setUserLocation({
          ...location,
          coordinates: selectedCoordinates,
          address: `${location.country}, ${location.city}`,
        }),
      );
    } else {
      navigate('/');
    }
  };

  const getFlagImage = (countryCode: string) => {
    try {
      return require(`images/flags/${countryCode}.svg`);
    } catch (error) {
      return null;
    }
  };

  return (
    <>
      <div className='flex flex-col items-center gap-2'>
        {avaliableLocations?.length && <h3>{t('we_already_in')}</h3>}
        <p className='text-center text-sm text-secondary'>
          {t('select_location')}
        </p>
      </div>
      <div className='mt-2 flex w-full flex-col items-center gap-2 overflow-y-auto pr-2 sm:max-h-[80vh]'>
        {!loading &&
          isLoaded &&
          avaliableLocations?.map((locationItem, i) => (
            <Button
              variant='grayStroke'
              key={locationItem.city + i}
              onClick={() => handleSelectNewLocation(locationItem)}
              className={`inline-flex w-full items-center gap-2 ${
                location.city === locationItem.city
                  ? '!border-accent'
                  : 'border-gray-3'
              } text-left`}
            >
              {getFlagImage(locationItem.country) && (
                <img
                  src={getFlagImage(locationItem.country)}
                  alt={`${locationItem.country} flag`}
                  width={20}
                  height={20}
                />
              )}
              <span className='text-sm'>
                {locationItem.city} ({locationItem.country})
              </span>
            </Button>
          ))}
        {!loading && !avaliableLocations?.length && (
          <p className='text-center text-md'>{t('something_wrong')}</p>
        )}
      </div>
      <div className='mx-auto flex w-full justify-center py-2 sm:w-3/4 smMax:fixed smMax:bottom-0 smMax:left-0 smMax:bg-white'>
        <Button
          variant='yellow'
          disabled={!location.city}
          onClick={handleCheckCity}
          className='w-[90%] py-4 sm:w-full'
        >
          {avaliableLocations?.length
            ? t('check_city')
            : t('add_service.subcategory_go_back')}
        </Button>
      </div>
    </>
  );
};

export default LocationSelector;
