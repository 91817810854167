import React from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ActionButtons, SectionHeader, Wrapper } from 'ui';

const DeleteAccountInfoPage = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = () => {
    navigate('/profile/delete');
  };

  return (
    <Wrapper flexColumnSpaceBetween>
      <div>
        <SectionHeader
          title={t('delete_account.title')}
          subtitle={t('delete_account.subtitle')}
        />
        <div className='mb-7 mt-5 space-y-5'>
          <p className=''>
            Data Deletion Instructions At GotoU.me we respect your privacy and
            are committed to ensuring that your data can be deleted easily if
            you choose to do so. If you would like to delete your account and
            associated data, please follow these steps:
          </p>
          <ol className='flex flex-col gap-4'>
            <li>
              <span className='text-accent'>1. </span>
              Log into your account on GotoU.me as Specialist or as Client.
            </li>
            <li>
              <span className='text-accent'>2. </span>
              Go to the Settings section.
            </li>
            <li>
              <span className='text-accent'>3. </span>
              Select Support and choose Delete Account.
            </li>
            <li>
              <span className='text-accent'>4. </span>
              Confirm your decision to delete your data. Alternative Option: If
              you are unable to delete your account using the above steps, you
              may request data deletion by contacting our support team at
              <strong> info@gotou.me</strong>. Please include "Data Deletion
              Request" in the subject line, along with your account information.
            </li>
          </ol>
        </div>
      </div>
      <ActionButtons
        saveButtonText='Delete account'
        cancelButtonText='Cancel'
        isSaveButtonDisabled={!isLoggedIn}
        handleCancelClick={handleCancelClick}
        handleSaveClick={handleSaveClick}
      />
    </Wrapper>
  );
};

export default DeleteAccountInfoPage;
