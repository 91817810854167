import React from 'react';
import TimeIcon from 'images/emoji/Hours.svg';
import { OrderTimeRange, UserAvatar } from 'ui';
import { Order } from 'utils/types';

type OrderCardWithAvatarProps = {
  order: Order['orders'][number];
};

const OrderCardWithAvatar = ({ order }: OrderCardWithAvatarProps) => {
  return (
    <section className='flex flex-row items-start justify-between'>
      <div className='flex items-center gap-2'>
        <img src={TimeIcon} alt='Time emodji' />
        <OrderTimeRange
          timeOfOrder={order.timeOfOrder}
          dur={order.dur}
          wrapperClassName='!text-primary'
        />
      </div>
      <div>
        <p className='mb-2 font-medium'>{order.clientName}</p>
        <p className='mb-2 text-xs text-[#4D4D4D] underline'>
          {order.clientPhoneNumber}
        </p>
        <p className='text-xs text-gray-1'>{order.serviceName}</p>
      </div>
      <div>
        <UserAvatar
          src={order.clientPhotoUrl}
          size={39}
          showLikeButton={false}
        />
      </div>
    </section>
  );
};

export default OrderCardWithAvatar;
