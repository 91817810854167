import Avatar1 from 'images/main-specialist/avatar1.jpg';
import Avatar2 from 'images/main-specialist/avatar2.jpg';
import Avatar3 from 'images/main-specialist/avatar3.jpg';

export const mockReviewsBusiness = [
  {
    avatar: Avatar1,
    name: 'Maryna',
    review: 'Convenient interface, customer registration has become easier!',
    occupation: 'Hairdresser and colorist',
  },
  {
    avatar: Avatar2,
    name: 'Oleksandr',
    review:
      'Now I can finally focus on work and not on organizational issues! It is extremely convenient!',
    occupation: 'Barber',
  },
  {
    avatar: Avatar3,
    name: 'Lusy',
    review:
      "I'm glad I tried GotoU. This is a convenient application that facilitates work processes.",
    occupation: 'Barber',
  },
];

export const guidesSpecialist = (
  t: any,
): {
  step: number;
  title: string;
  description: string[];
  list: string[];
  postDescription?: string[];
}[] => t('businessPage.guide.steps', { returnObjects: true });
