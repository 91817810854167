import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

type MultilineInputProps = {
  placeholder?: string;
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  variant?: 'default' | 'outline';
  maxRows?: number;
  onBlur?: () => void;
  onFocus?: () => void;
  [key: string]: any;
};

const MultilineInput = React.forwardRef<
  HTMLTextAreaElement,
  MultilineInputProps & MultilineInputProps
>(
  (
    {
      placeholder = '',
      className = '',
      value,
      onChange,
      hasError,
      required,
      disabled,
      readOnly,
      variant = 'default',
      maxRows,
      onBlur,
      onFocus,
      onIconClick,
      iconTitle,
      helperText,
      Icon,
      wrapperClassName,
      iconClassName,
      ...props
    },
    ref,
  ) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value.replace(/<[^>]*>?/gm, '');

      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: newValue,
          },
        });
      }
    };

    return (
      <div className={`input-with-placeholder relative ${wrapperClassName}`}>
        <ReactTextareaAutosize
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          maxRows={maxRows}
          onFocus={onFocus}
          onBlur={onBlur}
          cols={1}
          className={`${className} ${
            hasError
              ? 'border-error hover:border-error focus:border-error'
              : 'border-secondary'
          } my-1 w-full ${
            variant === 'outline'
              ? 'rounded-lg border px-2 py-4'
              : 'rounded-none border-b-[1.5px]'
          } resize-none outline-none transition-all hover:border-accent focus:border-accent`}
          {...props}
        />
        {Icon && typeof Icon === 'function' && (
          <Icon
            onClick={onIconClick}
            title={iconTitle}
            size={20}
            className={`absolute right-2 top-1/2 -translate-y-1/2 transform ${iconClassName}`}
          />
        )}
        {Icon && typeof Icon !== 'function' && (
          <span
            className={`absolute right-2 top-1/2 inline-flex -translate-y-1/2 items-center justify-center gap-2 ${iconClassName}`}
          >
            {Icon}
          </span>
        )}
        {helperText && (
          <p
            className={`${
              hasError ? 'text-error' : ''
            } -mt-2 mb-2 break-words text-sm`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  },
);

export default MultilineInput;
