import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

type DropdownProps = {
  show: boolean;
  verticalPosition?: 'bottom' | 'center';
  position?: 'center' | 'left' | 'right';
  children: React.ReactNode;
  disableAbsolutePositioning?: boolean;
  className?: string;
};

const Dropdown = ({
  show,
  children,
  className = '',
  verticalPosition = 'bottom',
  position = 'center',
  disableAbsolutePositioning = false,
}: DropdownProps) => {
  const getPositionClasses = () => {
    if (disableAbsolutePositioning) return 'bg-white';

    let horizontalClass = '';
    switch (position) {
      case 'left':
        horizontalClass = 'right-[0%]';
        break;
      case 'right':
        horizontalClass = 'left-[0%]';
        break;
      case 'center':
        horizontalClass = '-left-1/2';
        break;
    }

    let verticalClass = '';
    switch (verticalPosition) {
      case 'bottom':
        verticalClass = 'top-full';
        break;
      case 'center':
        verticalClass = 'top-1/2 -translate-y-1/2';
        break;
    }

    return `absolute ${horizontalClass} ${verticalClass} bg-[#F6F5F4]`;
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={`${getPositionClasses()} z-20 mt-3 flex min-w-max flex-col gap-4 rounded-xl px-9 py-10 shadow-lg ${className}`}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Dropdown;
