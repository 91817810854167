import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import MainHeader from 'components/Main/MainHeader';
import FAQSection from 'components/Main/FAQSection';
import FindSpecialistSection from 'components/Main/FindSpecialistSection';
import FooterSection from 'components/Main/FooterSection';
import GuideSection from 'components/Main/GuideSection';
import HeroSection from 'components/Main/HeroSection';
import ReviewSection from 'components/Main/ReviewSection';
import ServiceFeaturesSection from 'components/Main/ServiceFeaturesSection';
import SkinIssueSection from 'components/Main/SkinIssueSection';
import WhyChooseUsSection from 'components/Main/WhyChooseUsSection';
import SkinIssues from 'images/main-page/woman-suffering-from-body-dysmorphia 1.webp';
import HairdresserImg from 'images/main-page/Foto.webp';
import HairIssueSection from 'components/Main/HairIssuesSection';
import ServicesSection from 'components/Main/ServicesSection';

const navigationLinks = (t: any) => [
  {
    title: t('hairdresserPage.navigation.client'),
    navigationRole: 'client' as const,
    href: '/login',
  },
  {
    title: t('hairdresserPage.navigation.provider'),
    navigationRole: 'specialist' as const,
    href: '/login',
  },
  {
    title: t('hairdresserPage.navigation.faq'),
    href: 'faq',
  },
];

const MainPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);

    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const { pathname } = useLocation();
  const isBotoxPage = pathname.includes('botox');

  return (
    <main className='main-page-gap flex flex-col items-center bg-[#F6F5F4] font-sans'>
      <MainHeader />
      <HeroSection />
      <ServiceFeaturesSection />
      <img
        src={isBotoxPage ? SkinIssues : HairdresserImg}
        alt='woman-suffering-from-body-dysmorphia'
        loading='lazy'
        className='main-page-wrapper aspect-[1343/626] w-full max-w-[1440px] object-cover'
      />
      {isBotoxPage ? <SkinIssueSection /> : <HairIssueSection />}
      {!isBotoxPage && <ServicesSection />}
      <WhyChooseUsSection />
      <FindSpecialistSection />
      <GuideSection />
      <ReviewSection />
      <FAQSection />
      <FooterSection links={navigationLinks(t)} role='specialist' />
    </main>
  );
};

export default MainPage;
