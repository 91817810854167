import React, { useEffect, useRef, useState } from 'react';
import {
  Wrapper,
  SectionHeader,
  Button,
  Sheet,
  DetailedArchiveOrderButton,
} from 'ui';
import { useApiRequest } from 'hooks/useApiRequest';
import { useAppSelector } from 'hooks/useAppSelector';
import ShortInfoOrderItem from 'components/Order/ShortInfoOrderItem';
import LogoSad from 'images/icons/logo-sad.svg';
import { BASE_URL } from 'api/api';
import { useNavigate } from 'react-router';
import BoxIcon from 'images/emoji/Box.png';
import { IoClose } from 'react-icons/io5';
import { Order, OrderWithCoordinates } from 'utils/types';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { getTypedStorageItem } from 'utils/storage';

const OrdersPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { uid } = useAppSelector((state) => state.user);

  const [LIBRARIES] = useState<Libraries>(['places']);
  const [isOpenArchiveOrders, setIsOpenArchiveOrders] = useState(false);
  const [margin, setMargin] = useState(0);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      const height = divRef.current.clientHeight;
      setMargin(height);
    }
  }, []);

  const { data: activeOrders, loading: loadingActiveOrders } = useApiRequest<
    OrderWithCoordinates[]
  >({
    endpoint: `${BASE_URL}orders/shortInfoWithPrice/${uid}`,
    destructuringDataType: 'calendar',
  });
  const { data: archiveOrders, loading: loadingArchiveOrders } = useApiRequest<
    Order[]
  >({
    endpoint: `${BASE_URL}orders/archive/shortInfoWithPrice/${uid}`,
    destructuringDataType: 'calendar',
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: LIBRARIES,
  });

  const locale = getTypedStorageItem('selectedLanguage') || 'en';

  return (
    <Wrapper flexColumnSpaceBetween>
      <div
        className='flex flex-1 flex-col gap-4 sm:!mb-0'
        style={{
          marginBottom: `${margin}px`,
        }}
      >
        <SectionHeader title={t('menu.my_orders')} />
        {loadingActiveOrders && activeOrders?.length === 0 && (
          <div className='mt-6 h-24 w-full animate-pulse rounded bg-secondary' />
        )}
        {!!activeOrders?.length && (
          <div className='flex h-full flex-1 flex-col sm:max-h-[85vh] sm:overflow-y-auto'>
            {activeOrders.map((orderItem, i) => (
              <ShortInfoOrderItem
                orderItem={orderItem}
                isLoadedMap={isLoaded}
                key={orderItem.day + i}
              />
            ))}
          </div>
        )}
        {!loadingActiveOrders && activeOrders && activeOrders.length === 0 && (
          <div className='mt-10 flex flex-col items-center justify-center'>
            <img
              src={LogoSad}
              alt='logo'
              width={37}
              height={37}
              className='mb-5'
            />
            <h5 className='text-lg  text-primary'>{t('order.no_orders')}</h5>
            <p className='text-sm text-secondary'>
              {t('order.no_orders.description')}
            </p>
          </div>
        )}
        {archiveOrders && archiveOrders.length > 0 && isOpenArchiveOrders && (
          <Sheet
            className='max-h-[60vh] !pt-16'
            handleClose={() => setIsOpenArchiveOrders(false)}
          >
            <IoClose
              className='fixed right-5 top-3 cursor-pointer'
              size={30}
              onClick={() => setIsOpenArchiveOrders(false)}
              aria-label='close'
              title='Close archive orders'
            >
              {t('close')}
            </IoClose>
            <div className='flex flex-col gap-4 overflow-y-auto pr-2'>
              {archiveOrders.reverse().map((orderObj) => {
                return orderObj.orders.map((order) => {
                  return (
                    <DetailedArchiveOrderButton
                      key={order.idStr}
                      order={order}
                      orderDay={orderObj.day}
                      url={`/orders/${order.idStr}`}
                    />
                  );
                });
              })}
            </div>
          </Sheet>
        )}
      </div>
      <div
        className={`flex w-full justify-evenly gap-2 smMax:fixed smMax:bottom-0 smMax:-ml-5 smMax:bg-white smMax:px-2 smMax:py-3 max-350:flex-col  ${
          locale !== 'en' ? 'flex-col' : ''
        }`}
        ref={divRef}
      >
        <Button
          variant='yellow'
          onClick={() => navigate('/category')}
          className='w-full py-4 sm:gap-2 smMax:gap-1 '
        >
          {t('my_orders.book_new_order')}
        </Button>
        <Button
          variant='yellowStroke'
          onClick={() => {
            if (archiveOrders) {
              setIsOpenArchiveOrders(!isOpenArchiveOrders);
              return;
            }
          }}
          disabled={loadingArchiveOrders || archiveOrders?.length === 0}
          className='inline-flex w-full items-center justify-center gap-1 py-4 text-center'
        >
          <img src={BoxIcon} alt='emodji carton box' width={20} height={20} />
          {t('my_orders.archive_orders')}
        </Button>
      </div>
    </Wrapper>
  );
};

export default OrdersPage;
