import React, { useState } from 'react';
import {
  Button,
  OrderServiceForm,
  SectionHeader,
  toastWithDismiss,
  Wrapper,
} from 'ui';
import BG from 'images/backgrounds/bg-create-order.webp';
import { useApiRequest } from 'hooks/useApiRequest';
import { BASE_URL, createOrder } from 'api/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { Order, Service } from 'utils/types';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  addChosenService,
  setIsOpenSelectCustomer,
  setIsCalendarOpen,
  clearChosenServices,
  resetState,
} from 'redux/createOrderPageSlice';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const allDays = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const CreateOrderPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    dateStamp,
    customerName,
    chosenServices,
    isOpenSelectCustomer,
    isCalendarOpen,
    phoneNumber,
    clientId,
    isTimeSelected,
    timeSlotId,
  } = useAppSelector((state) => state.createOrderPage);

  const isSubmitButtonDisabled =
    !phoneNumber ||
    !customerName ||
    chosenServices.length === 0 ||
    !dateStamp ||
    !isTimeSelected;

  const { uid, role } = useAppSelector((state) => state.user);
  const currentDay = dateStamp
    ? new Date(dateStamp).getDate()
    : new Date().getDate();
  const month = dateStamp
    ? new Date(dateStamp).getMonth() + 1
    : new Date().getMonth() + 1;
  const year = dateStamp
    ? new Date(dateStamp).getFullYear()
    : new Date().getFullYear();

  const { data: allowedDays } = useApiRequest<number[]>({
    endpoint: `${BASE_URL}orders/${uid}/availableDays/${month}/${year}`,
    destructuringDataType: 'days',
    deps: month,
  });

  const { data: todayOrders } = useApiRequest<Order[]>({
    endpoint: `${BASE_URL}orders/shortInfo/${uid}/${currentDay}/${month}/${year}`,
    destructuringDataType: 'calendar',
    deps: currentDay,
  });

  const orderDays = todayOrders?.map((order) => order.orders).flat();

  const handleAddOrder = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!dateStamp) {
      toastWithDismiss('Select time', 'error');
      return;
    }

    setIsLoading(true);
    const response = await createOrder(
      {
        clientId,
        specialistId: uid,
        price: chosenServices[0]?.gssDto.price!,
        serviceId: chosenServices[0]?.gssDto.gserviceIdStr!,
        currency: chosenServices[0]?.gssDto.currency!,
        duration: chosenServices[0]?.gssDto.duration!,
        ts: dateStamp,
        timeSlotId,
        role,
      },
      t,
    );
    setIsLoading(false);

    if (response?.orderIdStr) {
      toast.dismiss();
      toastWithDismiss(t('order_success'), 'success');
      dispatch(resetState());
      navigate('/specialist/orders', {
        state: dateStamp,
      });
    }
  };

  const toggleCalendarVisibility = () => {
    if (phoneNumber && customerName && chosenServices.length > 0) {
      dispatch(setIsCalendarOpen(!isCalendarOpen));
    } else {
      toastWithDismiss(
        `Please ${
          !phoneNumber ? 'select your customer' : 'select your service'
        }`,
        'error',
      );
    }
  };

  const handleChooseService = (service: Service) => {
    dispatch(clearChosenServices());
    dispatch(addChosenService(service));
  };

  const handleToggleIsOpenSelectCustomer = () => {
    dispatch(setIsOpenSelectCustomer(!isOpenSelectCustomer));
  };

  return (
    <Wrapper noHorizontalPadding flexColumnSpaceBetween>
      <div>
        <SectionHeader title={t('menu.create_order')} wrapperClassName='px-4' />
        <img
          src={BG}
          alt='service background'
          loading='lazy'
          height={225}
          width={450}
          className='mb-5 mt-2 aspect-[2/1] w-full'
        />
        <OrderServiceForm
          allowedDays={
            allowedDays && allowedDays?.length > 0 ? allowedDays : allDays
          }
          chosenServices={chosenServices}
          isCustomerSelectionOpen={isOpenSelectCustomer}
          isDatePickerOpen={isCalendarOpen}
          onOrderSubmit={handleAddOrder}
          toggleDatePicker={toggleCalendarVisibility}
          toggleCustomerSelect={handleToggleIsOpenSelectCustomer}
          specialistOrders={orderDays}
          handleChooseService={handleChooseService}
        />
      </div>
      {!isOpenSelectCustomer && (
        <div className='mt-5 px-5'>
          <Button
            className='w-full py-4'
            variant='yellow'
            disabled={isSubmitButtonDisabled}
            loading={isLoading}
            type='submit'
            form='create-order'
          >
            {t('profile.create_order')}
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default CreateOrderPage;
