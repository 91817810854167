import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useAppSelector } from 'hooks/useAppSelector';
import ProfileAvatarLink from './ProfileAvatarLink';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Person from 'images/icons/Person.svg';
import { IoCloseOutline } from 'react-icons/io5';
import { Dropdown } from 'ui';

type ProfileButtonProps = {
  handleLogOut: () => void;
};

const SpecialistLinks = (t: TFunction<'translation', undefined>) => (
  <>
    <ProfileAvatarLink
      text={t('menu.my_services')}
      link='/specialist/services'
    />
    <ProfileAvatarLink text={t('menu.orders')} link='/specialist/orders' />
    <ProfileAvatarLink
      text={t('profile.create_order')}
      link='/specialist/create-order'
    />
    <ProfileAvatarLink text={t('menu.calendar')} link='/specialist/calendar' />
    <ProfileAvatarLink text={t('menu.schedule')} link='/specialist/schedule' />
  </>
);

const ClientLinks = (t: TFunction<'translation', undefined>) => (
  <>
    <ProfileAvatarLink text={t('profile.create_order')} link='/category' />
    <ProfileAvatarLink text={t('menu.my_orders')} link='/orders' />
    <ProfileAvatarLink text={t('menu.my_favorites')} link='/favorites' />
    <ProfileAvatarLink text={t('menu.edit_profile')} link='/profile/edit' />
  </>
);

const ProfileMenuButton = ({ handleLogOut }: ProfileButtonProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { role, profilePhoto } = useAppSelector((state) => state.user);

  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='relative inline-block h-12' ref={ref}>
      <button onClick={toggleDropdown}>
        <img
          src={profilePhoto || Person}
          alt='profile button'
          width={48}
          height={48}
          className={`${
            profilePhoto ? '' : 'bg-[#FAE18B] p-[10px]'
          } h-12 w-12 rounded-full border border-[#2D2C2C] xs:h-10 xs:w-10`}
        />
      </button>
      <Dropdown
        show={isOpen}
        position='left'
        className='z-30 w-64 !gap-0 !py-0 text-left shadow-xl'
      >
        <IoCloseOutline
          onClick={toggleDropdown}
          size={24}
          className='absolute right-3 top-3 cursor-pointer text-3xl hover:scale-110'
        />
        <ul className='py-1 pt-5' onClick={toggleDropdown}>
          {role === 'specialist' && SpecialistLinks(t)}
          {role === 'client' && ClientLinks(t)}
          <ProfileAvatarLink text={t('menu.support')} link='/support' />
          <ProfileAvatarLink text={t('menu.about_gotou')} link='/' />
        </ul>
        <div className='border-t border-gray-8 py-1'>
          <button
            className='block w-full px-1 py-3 text-left leading-tight hover:underline'
            onClick={handleLogOut}
          >
            {t('menu.logout')}
          </button>
        </div>
      </Dropdown>
    </div>
  );
};

export default ProfileMenuButton;
