import React, { useCallback } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ModalState, closeModal } from 'redux/modalSlice';

type ModalHandlingProps = {
  handleCloseModal: () => void;
};

export function withModalHandling<P extends object>(
  WrappedComponent: React.ComponentType<P & ModalHandlingProps>,
  modalType: keyof ModalState,
) {
  return (props: P) => {
    const dispatch = useAppDispatch();

    const handleCloseModal = useCallback(() => {
      dispatch(closeModal(modalType));
    }, [dispatch]);

    return <WrappedComponent {...props} handleCloseModal={handleCloseModal} />;
  };
}
