import React from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { setUserRole } from 'redux/userSlice';
import { Button } from 'ui';
import ReactGA from 'react-ga';
import { useAppSelector } from 'hooks/useAppSelector';
import AcceptToast from 'modals/AcceptToast';
import { logOut } from 'api/login';
import toast from 'react-hot-toast';

const FindSpecialistSection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isBotoxPage = pathname.includes('botox');
  const { isLoggedIn, role, uid } = useAppSelector((state) => state.user);

  const handleLogin = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Find a provider Button',
      label: 'FindSpecialistSection',
    });

    if (!isLoggedIn) {
      dispatch(setUserRole('specialist'));
      navigate('/login');
    } else {
      if (role === 'specialist') {
        navigate('/specialist/profile');
      } else {
        AcceptToast({
          saveButtonText: t('menu.logout'),
          cancelButtonText: t('client.remove_favorite.no'),
          text: t('message.login_client'),
          handleSaveClick: async () => {
            await logOut(
              uid,
              role as 'client' | 'specialist',
              dispatch,
              navigate,
            );
            dispatch(setUserRole('specialist'));
            navigate('/login');
            toast.dismiss();
          },
          duration: 100000,
        });
      }
    }
  };

  return (
    <section
      className={` ${
        isBotoxPage
          ? 'bg-find-specialist-section'
          : 'bg-find-specialist-section-hair'
      } flex max-h-[590px] w-[95%] max-w-[1440px] items-center rounded-[20px] !px-3 !pb-[45px] !pt-[60px] md:mx-[2.5rem] md:h-[590px] md:!pl-[82px]`}
      style={{
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className='flex flex-col gap-6'>
        <h3 className='md-max-w-[230px] max-w-[44%] font-sans text-[1.625rem] leading-[2.4375rem] text-[#2D1D1C] md:text-[3.45rem] md:leading-[5.12rem]'>
          {t('hairdresserPage.findSpec')}
        </h3>
        <Button
          onClick={handleLogin}
          variant='yellowStroke'
          className='max-w-[230px] !border-black !bg-[#FAE18B] font-sans text-md2 hover:!bg-accent-80'
        >
          {t('hairdresserPage.buttons.provider')}
        </Button>
      </div>
    </section>
  );
};

export default FindSpecialistSection;
