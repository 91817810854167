import SpecialistOccupiedToast from 'modals/SpecialistOccupiedToast';
import { Currency, LocationDto, Roles } from 'utils/types';
import { store } from 'store';
import {
  getTypedStorageItem,
  removeTypedStorageItem,
  setTypedStorageItem,
} from 'utils/storage';
import { logOutUser } from 'redux/actions';

export const BASE_URL = process.env.REACT_APP_BASE_URL || '';

type Header = {
  'Content-Type': 'application/json' | 'multipart/form-data';
  Authorization?: string;
};

export const getHeaders = (): Header => {
  const token = getTypedStorageItem('gotouAccessToken');
  if (token) {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      'Content-Type': 'application/json',
    };
  }
};

export const handleTokenRefresh = async () => {
  const newTokens = await refreshTokens();
  setTypedStorageItem('gotouAccessToken', newTokens.accessToken);
  setTypedStorageItem('gotouRefreshToken', newTokens.refreshToken);
  setTypedStorageItem('gotouTokenType', newTokens.tokenType);
};

const handleResponse = async <T = any>(
  response: Response,
  parseJson = true,
): Promise<T> => {
  if (!response.ok) {
    let message = 'Something went wrong';
    switch (response.status) {
      case 400:
        message = 'Bad Request';
        break;
      case 401:
        await handleTokenRefresh();
        message = 'Unauthorized';
        break;
      case 404:
        message = 'Not Found';
        break;
      case 910:
        if (response.url.includes('getTrialEndDate')) {
          message = 'No subscription for trial';
        }
        break;
      case 909:
        if (response.url.includes('getTrialEndDate')) {
          message = 'Trial has started';
        }
        break;
      // Add more cases as needed
    }
    throw new Error(message);
  }
  return parseJson
    ? (response.json() as Promise<T>)
    : (response as unknown as Promise<T>);
};

export const fetchWithTokenRefresh = async (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body?: any,
  attemptedRefresh: boolean = false,
): Promise<any> => {
  const response = await fetch(`${BASE_URL}${url}`, {
    method,
    headers: getHeaders(),
    body: body ? JSON.stringify(body) : undefined,
  });

  if (response.status === 401 && !attemptedRefresh) {
    await handleTokenRefresh();
    return fetchWithTokenRefresh(url, method, body, true);
  }

  return response;
};

export const fetchRequestBoolean = async (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body?: any,
) => {
  try {
    const response = await fetchWithTokenRefresh(url, method, body);
    await handleResponse(response, false);
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchRequestData = async <T = any>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
  body?: any,
): Promise<T> => {
  const response = await fetchWithTokenRefresh(url, method, body);
  return handleResponse(response);
};

export const refreshTokens = async () => {
  const refreshToken = getTypedStorageItem('gotouRefreshToken');

  const response = await fetch(`${BASE_URL}login/refreshToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: refreshToken,
  });

  if (!response.ok) {
    removeTypedStorageItem('gotouAccessToken');
    removeTypedStorageItem('gotouRefreshToken');
    store.dispatch(logOutUser());
  }

  return await response.json();
};

export const acceptSpecialist = async (
  orderIdStr: string,
  specialistIdStr: string,
  timeOfOrder: string,
  timeSlotId: number,
) => {
  return await fetchRequestBoolean('specialist/accept', 'POST', {
    orderId: orderIdStr,
    specialistId: specialistIdStr,
    timeOfOrder,
    timeSlotId,
  });
};

export const updateSpecialistNameAndLocation = async (
  nameBody: {
    firstName: string;
    lastName: string;
  },
  locationBody: LocationDto & { placeId?: string },
) => {
  const responseLocation = await fetchRequestBoolean(
    `specialists/location`,
    'POST',
    locationBody,
  );
  const responseName = await fetchRequestBoolean(
    `specialists/update`,
    'POST',
    nameBody,
  );
  return responseLocation && responseName;
};

export const updateClientNameAndLocation = async (
  body: {
    email?: string;
    firstName: string;
    id: string;
    lastName: string;
    middleName?: string;
  },
  addressBody: {
    lat: number;
    lon: number;
    name: string;
    city: string;
    country: string;
    placeId?: string;
  },
) => {
  const responseLocation = await updateClientAddress(addressBody);
  const responseName = await fetchRequestBoolean(
    `clients/updateProfile`,
    'POST',
    body,
  );
  return responseLocation && responseName;
};

export const addFavoriteSpecialist = async (
  uid: string,
  specialistId: string,
) => {
  return await fetchRequestBoolean(
    `favourites/add/${uid}/${specialistId}`,
    'POST',
  );
};

export const removeFavoriteSpecialist = async (
  uid: string,
  specialistId: string,
) => {
  return await fetchRequestBoolean(
    `favourites/delete/${uid}/${specialistId}`,
    'POST',
  );
};

export const cancelOrderByRole = async (
  orderId: string,
  cancelReason: string,
  role: Roles,
) => {
  const url =
    role === 'client'
      ? `orders/cancelByClient/${orderId}`
      : `orders/cancelBySpec/${orderId}`;
  return await fetchRequestBoolean(`${url}`, 'POST', { cancelReason });
};

export const finishOrder = async (orderId: string) => {
  return await fetchRequestBoolean(`orders/${orderId}/finish`, 'POST');
};

export const cancelOrderBySpecialsit = async (
  orderId: string,
  cancelReason: string,
) => {
  return await fetchRequestBoolean(`orders/cancelBySpec/${orderId}`, 'POST', {
    cancelReason,
  });
};

export const updateSpecialistSchedule = async (
  uid: string,
  workHours: { days: string[]; timeFrames: { from: string; to: string }[] },
  weekendHours: { days: string[]; timeFrames: { from: string; to: string }[] },
) => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await fetchRequestBoolean(
      `specialists/${uid}/schedule`,
      'POST',
      {
        dtos: [
          { days: workHours.days, timeFrames: workHours.timeFrames, timezone },
          {
            days: weekendHours.days,
            timeFrames: weekendHours.timeFrames,
            timezone,
          },
        ],
      },
    );

    if (!response) {
      throw new Error('Something went wrong');
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const updateSpecialistService = async (
  uid: string,
  serviceId: string,
  service: {
    breakAfter?: number;
    duration: number;
    price: number;
    name: string;
    subcategoryId: string;
    currency: Currency;
    serviceId: string;
    isOnline: boolean;
    isGroup: boolean;
  },
) => {
  try {
    const response = await fetchRequestBoolean(
      `services/update/${uid}/${serviceId}`,
      'POST',
      service,
    );

    if (!response) {
      throw new Error('Something went wrong');
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const updateUserAvatar = async (
  photo: File,
  role: 'client' | 'specialist',
) => {
  try {
    const url = role === 'specialist' ? 'specialists' : 'clients';
    const urlUpload = role === 'specialist' ? 'photo' : 'uploadFile';

    const deleteResponse = await fetchWithTokenRefresh(
      `${url}/deletePhoto`,
      'DELETE',
    );
    if (!deleteResponse.ok) {
      throw new Error('Something went wrong');
    }

    const formData = new FormData();
    formData.append('file', photo);
    const response = await fetch(`${BASE_URL}${url}/${urlUpload}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${getTypedStorageItem('gotouAccessToken')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    const photoURL = await response.text();

    return photoURL;
  } catch (error) {
    return false;
  }
};

export const addMediaToGallery = async (
  uid: string,
  media: File[],
  refreshedToken?: boolean,
) => {
  try {
    const formData = new FormData();
    media.forEach((file) => formData.append('files', file));
    formData.append('mediaType', 'photo');
    const response = await fetch(`${BASE_URL}specialists/addPhotosToGallery`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${getTypedStorageItem('gotouAccessToken')}`,
      },
    });

    if (response.status === 401 && !refreshedToken) {
      await handleTokenRefresh();
      await addMediaToGallery(uid, media, true);
      return;
    }

    return response;
  } catch (error) {
    return false;
  }
};

export const deletePhotoFromGallery = async (photosUrls: string[]) => {
  return await fetchRequestBoolean(
    `specialists/deleteManyFromGallery`,
    'DELETE',
    { urls: photosUrls },
  );
};

export const createOrder = async (
  {
    clientId,
    specialistId,
    price,
    serviceId,
    duration,
    ts,
    role,
    currency,
    timeSlotId,
  }: {
    currency: Currency;
    clientId: string;
    specialistId: string;
    price: number;
    serviceId: string;
    duration: number;
    ts: number;
    timeSlotId: number;
    role: Roles;
  },
  t: any,
) => {
  const response: Response = await fetchWithTokenRefresh(
    `specialist/createOrderBySpecialist`,
    'POST',
    {
      clientId,
      specialistId,
      duration,
      price,
      currency,
      timeSlotId,
      ts,
      serviceIds: [serviceId],
      orderSource: role === 'client' ? 'byClient' : 'bySpecialist',
    },
  );

  if (response.status === 702 || response.status === 701) {
    SpecialistOccupiedToast(t);
    return null;
  }

  if (!response.ok) {
    return null;
  }

  const result: {
    orderId: number;
    orderIdStr: string;
  } = await response.json();

  return result;
};

export const addClient = async (
  specialistId: string,
  body: {
    phone: string;
    name: string;
    surname: string;
  },
) => {
  const url = `specialists/${specialistId}/addClient`;
  return await fetchRequestData<{
    clientId: number;
    clientIdStr: string;
  }>(url, 'POST', body);
};

export const updateClientAddress = async (body: {
  lat: number;
  lon: number;
  name: string;
  city: string;
  country: string;
  placeId?: string;
}) => {
  const url = `clients/updateAddress`;
  return await fetchRequestBoolean(url, 'POST', body);
};

export const getClientAddress = async () => {
  const url = `clients/location`;
  return await fetchRequestData<{
    city: string;
    comment: string;
    country: string;
    fullName: string;
    houseNumber: string;
    latitude: number;
    longitude: number;
    placeName: string;
    postalCode: string;
    street: string;
  }>(url, 'GET');
};

export const addReview = async (
  specialistId: string,
  review: {
    clientId: string;
    comment: string;
    orderId: string;
    rating: number;
  },
) => {
  const url = `specialists/${specialistId}/addReview`;
  return await fetchRequestBoolean(url, 'POST', review);
};
