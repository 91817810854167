import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderFound } from 'utils/types';
import { logOutUser } from './actions';

const initialState: OrderFound & { canFetchNewOrder: boolean } = {
  address: '',
  currency: 'USD',
  found: false,
  imagesAmount: 0,
  name: '',
  orderId: 0,
  orderIdStr: '',
  phoneNumber: '',
  photoURL: '',
  price: 0,
  rank: 0,
  reviewsNumber: 0,
  serviceName: '',
  since: 0,
  specialistId: 0,
  specialistIdStr: '',
  timeOfOrder: '',
  canFetchNewOrder: true,
  duration: 0,
  latitude: 0,
  longitude: 0,
};

export const searchOrderSlice = createSlice({
  name: 'searchOrder',
  initialState,
  reducers: {
    setSearchOrderData: (state, action: PayloadAction<OrderFound>) => {
      Object.assign(state, action.payload);
    },
    setCanFetchNewOrder: (state, action: PayloadAction<boolean>) => {
      state.canFetchNewOrder = action.payload;
    },
    resetSearchOrder: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(logOutUser, () => initialState);
  },
});

export const { setSearchOrderData, setCanFetchNewOrder, resetSearchOrder } =
  searchOrderSlice.actions;

export default searchOrderSlice.reducer;
