import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { getTypedStorageItem } from 'utils/storage';

interface HeaderDatePickerProps extends ReactDatePickerCustomHeaderProps {
  setCurrMonth?: (month: number) => void;
}

const HeaderDatePicker = ({
  setCurrMonth,
  ...params
}: HeaderDatePickerProps) => {
  const handleDecreaseMonth = () => {
    if (params.prevMonthButtonDisabled) return;
    params.decreaseMonth();

    if (!setCurrMonth) return;
    const newMonth = params.date.getMonth();
    setCurrMonth(newMonth === 0 ? 12 : newMonth);
  };

  const handleIncreaseMonth = () => {
    if (params.nextMonthButtonDisabled) return;
    params.increaseMonth();

    if (!setCurrMonth) return;
    const newMonth = (params.date.getMonth() + 2) % 12;
    setCurrMonth(newMonth === 0 ? 12 : newMonth);
  };

  const locale = getTypedStorageItem('selectedLanguage') || 'en';

  return (
    <div className='mx-6 flex items-center justify-between'>
      <FaChevronLeft
        className={`cursor-pointer ${
          params.prevMonthButtonDisabled ? 'text-secondary ' : 'text-primary'
        }`}
        onClick={handleDecreaseMonth}
      />
      <h2 className='text-[1.3em]'>
        {params.date
          .toLocaleDateString(locale, { month: 'long' })
          .charAt(0)
          .toUpperCase() +
          params.date.toLocaleDateString(locale, { month: 'long' }).slice(1)}
      </h2>

      <FaChevronRight
        className={`cursor-pointer ${
          params.nextMonthButtonDisabled ? 'text-secondary ' : 'text-primary'
        }`}
        onClick={handleIncreaseMonth}
      />
    </div>
  );
};

export default HeaderDatePicker;
