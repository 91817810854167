import React from 'react';
import { useNavigate } from 'react-router';

type ProfileAvatarLinkProps = {
  text: string;
  link: string;
};

const ProfileAvatarLink = ({ text, link }: ProfileAvatarLinkProps) => {
  const navigate = useNavigate();
  const isExternal = link.startsWith('http');

  const handleNavigate = () => {
    navigate(link);
  };

  return (
    <li
      className='block cursor-pointer px-1 py-3 leading-tight transition-all hover:underline'
      onClick={!isExternal ? handleNavigate : undefined}
    >
      {isExternal ? (
        <a
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          className='block w-full'
        >
          {text}
        </a>
      ) : (
        text
      )}
    </li>
  );
};

export default ProfileAvatarLink;
