import React from 'react';
import NavigationItem from './NavigationItem';

type NavigationProps = {
  isVerticalLayout?: boolean;
  links: {
    title: string;
    navigationRole?: 'client' | 'specialist';
    href: string;
  }[];
  onClick?: () => void;
};

const Navigation = ({ isVerticalLayout, links, onClick }: NavigationProps) => {
  return (
    <ul
      className={`flex gap-3 sm:justify-around md:justify-start md:gap-6 xl:gap-12 ${
        isVerticalLayout
          ? ' mx-auto flex w-full flex-col items-start !gap-12'
          : 'xs:flex-col xs:items-center'
      }`}
    >
      {links.map((link) => (
        <NavigationItem
          key={link.title}
          isVerticalLayout={isVerticalLayout}
          onClick={onClick}
          {...link}
        />
      ))}
    </ul>
  );
};

export default Navigation;
